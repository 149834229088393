import ApiERP from '@/core/conectores/ApiERP';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IEstruturaDRE, IEstruturaDREGrupo } from '@/models/Relatorios/Financeiro/DRE/IEstruturaDRE';
import { IParametrosDRE } from '@/models/Relatorios/Financeiro/DRE/IParametrosDRE';
import { IResultadoDRE } from '@/models/Relatorios/Financeiro/DRE/IResultadoDRE';
import { IOrdenacaoRegistro } from '@/core/models/IOrdenacaoRegistro';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';

/**
 * Serviço de Relatório da DRE
 * Fornece todas regras de negócios e lógicas relacionado a DRE.
 */
class ServicoRelatorioDRE {
    endPoint = 'relatorio-dre';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
      const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
      return result.data;
    }

    public async consultaRapidaGrupo(parametrosConsultaRapida: IParametrosConsultaRapida, estrutura: number): Promise<IItemConsultaRapida[]> {
      const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida/grupo/${estrutura}${parametrosAdicionais}`);
      return result.data;
    }

    public async incluirEstrutura(conta: IEstruturaDRE): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}`, conta);
      return result.data;
    }

    public async alterarEstrutura(conta: IEstruturaDRE): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${conta.codigo}`, conta);
      return result.data;
    }

    public async excluirEstrutura(codigo: number, empresa: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async obterEstrutura(codigo: number, empresa: number): Promise<IEstruturaDRE> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async incluirGrupo(conta: IEstruturaDREGrupo, empresa: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/grupo/empresa/${empresa}`, conta);
      return result.data;
    }

    public async alterarGrupo(conta: IEstruturaDREGrupo, empresa: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/grupo/${conta.codigo}/empresa/${empresa}`, conta);
      return result.data;
    }

    public async excluirGrupo(codigo: number, empresa: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/grupo/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async atualizarOrdenacao(estrutura: number, empresa: number, ordenacaoRegistros: IOrdenacaoRegistro[]): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/grupos/${estrutura}/ordenacao/empresa/${empresa}`, ordenacaoRegistros);
      return result.data;
    }

    public async obterGrupo(codigo: number, empresa: number): Promise<IEstruturaDREGrupo> {
      const result: any = await this.apiERP.get(`${this.endPoint}/grupo/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async obterGrupos(codigo: number, empresa: number): Promise<IEstruturaDREGrupo[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/grupos/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async obterResultado(parametros: IParametrosDRE): Promise<IResultadoDRE[]> {
      const result: any = await this.apiERP.post(`${this.endPoint}/resultado`, parametros);
      return result.data;
    }

    public async ObterImpressao(tipoArquivo: ETipoArquivo, parametros: IParametrosDRE): Promise<IRetornoRelatorio> {
      const result: any = await this.apiERP.post(`${this.endPoint}/impressao/arquivo/${tipoArquivo}`, parametros);
      return result.data;
    }
}
export default ServicoRelatorioDRE;
