import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IDTOClienteFinanceiro } from '@/models/DTO/Cadastros/Pessoas/Clientes/IDTOClienteFinanceiro';
import { IDTOMovimentoFinanceiro } from '@/models/DTO/Financeiro/MovimentosFinanceiros/IDTOMovimentoFinanceiro';
import { IDTOTituloFinanceiro } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiro';
import { IAntecipacaoTitulos } from '@/models/Entidades/Financeiro/AntecipacaoTitulos/IAntecipacaoTitulosFinanceiros';
import { IBaixaTitulos } from '@/models/Entidades/Financeiro/BaixasTitulosFinanceiros/IBaixaTitulosFinanceiros';

/**
 * Serviço de Baixas de Títulos Financeiros
 * Fornece todas regras de negócios e lógicas relacionado a baixa de títulos financeiros.
 */

class ServicoAntecipacaoTitulos {
    endPoint = 'antecipacao-titulos';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async anteciparTitulos(antecipacao: IAntecipacaoTitulos): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}`, antecipacao);
      return result.data;
    }

    public async lancarBaixasTitulos(antecipacoes: IBaixaTitulos[]): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/antecipacoes-titulos`, antecipacoes);
      return result.data;
    }

    public async validarAlteracaoPercentuais(empresa: number): Promise<boolean> {
      const result: any = await this.apiERP.get(`${this.endPoint}/alteracao-percentuais/empresa/${empresa}`);
      return result.data;
    }

    public async obterDadosFinanceirosClientes(pessoas: number[], empresa: number): Promise<IDTOClienteFinanceiro[]> {
      let parametrosAdicionais = '';
      for (let contador = 0; contador < pessoas.length; contador += 1) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Pessoas=${pessoas[contador]}`;
      }
      const result: any = await this.apiERP.get(`${this.endPoint}/financeiro-cliente/empresa/${empresa}${parametrosAdicionais}`);
      return result.data;
    }

    public async estonarAntecipacao(antecipacao: number, empresa: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/estorno-antecipacao/${antecipacao}/empresa/${empresa}`);
      return result.data;
    }

    public async obterMovimentosFinanceiros(antecipacao: number, empresa: number): Promise<IDTOMovimentoFinanceiro[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/movimentos-financeiros/${antecipacao}/empresa/${empresa}`);
      return result.data;
    }

    public async obterTitulosLancados(antecipacao: number, empresa: number): Promise<IDTOTituloFinanceiro[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/titulos-lancados/${antecipacao}/empresa/${empresa}`);
      return result.data;
    }

  // public async obterTitulosBaixados(antecipacao: number, empresa: number): Promise<It]> {
  //  const result: any = await this.apiERP.get(`${this.endPoint}/titulos-baixados/${antecipacao}/empresa/${empresa}`);
  //  return result.data;
  // }
}
export default ServicoAntecipacaoTitulos;
