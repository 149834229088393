
import {
  defineComponent, reactive,
} from 'vue';
import { IEstruturaDREGrupo } from '@/models/Relatorios/Financeiro/DRE/IEstruturaDRE';
import { ETipoGrupoDRE } from '@/models/Enumeradores/Relatorios/ETipoGrupoDRE';
import Icone from '@/core/components/Icone.vue';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IColumn } from '@/core/models/AntDesign/IColumn';
import ResultadoDRE from './ResultadoDRE.vue';

export default defineComponent({
  name: 'GrupoResultadoDRE',
  props: {
    tipoPeriodo: {
      type: Number,
      required: true,
    },
    grupo: {
      type: Object as () => IEstruturaDREGrupo,
      required: true,
    },
    colunasGrande: {
      type: Array as () => IColumn[],
      required: true,
    },
  },
  components: {
    Icone,
    ResultadoDRE,
  },
  emits: ['editarGrupo'],
  setup(props, { emit }) {
    const state = reactive({
      expandir: false,
      idselecionados: [] as string[],
      indexSelecionado: -1,
      windowWidth: (window.innerWidth - 100),
    });

    function obterClasseGrupo() {
      if (props.grupo.tipo === ETipoGrupoDRE.Receitas) {
        return 'receitas';
      }
      if (props.grupo.tipo === ETipoGrupoDRE.Despesas) {
        return 'despesas';
      }
      return 'totalizador';
    }

    function obterSimboloGrupo() {
      if (props.grupo.tipo === ETipoGrupoDRE.Receitas) {
        return '+';
      }
      if (props.grupo.tipo === ETipoGrupoDRE.Despesas) {
        return '-';
      }
      return '=';
    }

    function expandir() {
      state.expandir = !state.expandir;
    }

    function editarGrupo() {
      emit('editarGrupo', props.grupo.codigo);
    }

    return {
      props,
      state,
      ETipoGrupoDRE,
      UtilitarioGeral,
      UtilitarioMascara,
      obterClasseGrupo,
      obterSimboloGrupo,
      expandir,
      editarGrupo,
    };
  },
});
