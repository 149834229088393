
import {
  defineComponent, onBeforeMount,
} from 'vue';
import { Modal } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import router from '@/router';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useGradeBase } from '@/core/composables/GradeBase';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { ETipoTituloFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoTituloFinanceiro';
import { IDTOTituloFinanceiro } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiro';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import SelecionarCategoriaPlanoConta from '@/components/Cadastros/PlanosContas/SelecionarCategoriaPlanoConta.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import storeSistema from '@/store/storeSistema';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import Totalizador from '@/core/components/Tela/Totalizador.vue';
import BuscarTitulosModal from '../../../components/Financeiro/TitulosFinanceiros/BuscarTitulosModal.vue';
import BaixaTitulosLancamento from '@/components/Financeiro/BaixaTitulosFinanceiros/BaixaTitulosLancamento.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { useModalBase } from '@/core/composables/ModalBase';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import SelecionarTituloFinanceiroPorNumero from '@/components/Financeiro/TitulosFinanceiros/SelecionarTituloFinanceiroPorNumero.vue';
import ServicoParametros from '@/servicos/MeuSistema/ServicoParametros';
import UtilitarioFinanceiro from '@/core/utilitarios/UtilitarioFinanceiro';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { IDTOLancamentoBaixaConta } from '@/models/DTO/Financeiro/BaixasTitulosFinanceiros/IDTOLancamentoBaixaConta';
import ServicoConta from '@/servicos/Cadastros/Financeiro/ServicoConta';
import storeFinanceiro from '@/store/storeFinanceiro';
import { useFocusBase } from '@/core/composables/FocusBase';
import { IDTOComunicacaoFinanceiroStore } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOComunicacaoFinanceiroStore';
import { IDTOTituloFinanceiroStore } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloStore';
import { useAntecipacaoTitulosBase } from '@/composables/Financeiro/AntecipacaoTituloBase';
import { IDTOTituloFinanceiroPendenteAntecipar } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroPendenteAntecipar';
import ServicoAntecipacaoTitulos from '@/servicos/Financeiro/ServicoAntecipacaoTitulos';

export interface IAlertaAntecipacao{
  titulo: string;
  mensagem: string;
  tipo: string;
  visivel: boolean;
}
export default defineComponent({
  name: 'AntecipacaoTitulos',
  components: {
    Card,
    Icone,
    SelecionarData,
    MensagemSemDados,
    SelecionarCategoriaPlanoConta,
    CampoNumerico,
    Totalizador,
    BuscarTitulosModal,
    BaixaTitulosLancamento,
    RequisicaoModal,
    SelecionarTituloFinanceiroPorNumero,
  },
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasDisponiveis, verificaAutorizacao,
      adicionarAtalho, removerAtalho, apresentarMensagemSucesso,
    } = useTelaBase();
    const {
      gradeBase, ordenacaoAtiva,
    } = useGradeBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);

    const {
      focusBase, proximoElemento, elementoAnterior, elementoAcima, elementoAbaixo,
    } = useFocusBase();
    focusBase.classElementos = 'ss-baixa-contas-receber-titulos-adicionados';

    const route = useRoute();
    const servicoAntecipacaoTitulo = new ServicoAntecipacaoTitulos();

    const servicoParametros = new ServicoParametros();
    const servicoSistema = new ServicoSistema();
    const servicoConta = new ServicoConta();
    servicoConta.requisicaoSistema();
    servicoAntecipacaoTitulo.requisicaoSistema();
    servicoParametros.requisicaoSistema();

    const {
      antecipacaoTitulosBase, limparTelaBase, populartitulosAntecipados, calcularValores, prepararPreviaAntecipar, obterDescricaoConta, obterDescricaoInfluenciaSaldo, verificacaoCalculo, valorTaxaDigitado, verificacaoAlteracaoData, obterObjetoAntecipacao,
    } = useAntecipacaoTitulosBase();
    antecipacaoTitulosBase.identificadorRecurso = 'ANTECIPACAO_TITULOS';
    antecipacaoTitulosBase.identificadorPermissao = 'AUT_ANTECIPACAO_TITULOS';
    gradeBase.layoutMobile = UtilitarioDispositivo.larguraTelaMobile();

    let listaTitulosSelecionados:IDTOTituloFinanceiro[] = [];
    let comunicacaoFinanceiro: IDTOComunicacaoFinanceiroStore = {} as IDTOComunicacaoFinanceiroStore;
    let storeTitulosFinanceiro: IDTOTituloFinanceiroStore = {} as IDTOTituloFinanceiroStore;

    function preencherColunas() {
      if (gradeBase.layoutMobile) {
        gradeBase.colunas = [
          {
            title: 'Código', dataIndex: 'codigoTituloFinanceiro', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'Título', dataIndex: 'titulo', key: 'TituloFinanceiro', position: 2, visible: true,
          },
        ];

        // Colunas - Movimentos da Baixa
        antecipacaoTitulosBase.colunasMovimentos = [
          {
            title: 'Movimento', dataIndex: 'movimento', key: 'MovimentoBaixa', position: 1, visible: true,
          },
        ];
      } else {
        // Colunas - Títulos selecionados Baixa
        gradeBase.colunas = [
          {
            title: 'Código', dataIndex: 'codigoTituloFinanceiro', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'PGTO', dataIndex: 'tipoDocumentoFinanceiro', key: 'TipoDocumentoFinanceiro', position: 2, visible: true, width: 40, ellipsis: true, fixed: 'left',
          },
          {
            title: 'N.Identificação', dataIndex: 'numeroTitulo', key: 'NumeroTitulo', position: 3, visible: true, ellipsis: true, width: 75, fixed: 'left',
          },
          {
            title: 'Pessoa', dataIndex: 'nomePessoa', key: 'NomePessoa', position: 4, visible: true, ellipsis: true, width: 150, fixed: 'left',
          },
          {
            title: 'Vencimento', dataIndex: 'dataVencimento', key: 'DataVencimento', position: 5, visible: true, ordering: false, customRenderRow: ECustomRenderRow.MascararData, width: 60, align: 'center', fixed: 'left',
          },
          {
            title: 'Valor Título R$', dataIndex: 'saldo', key: 'Valor', position: 6, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 75, fixed: 'left',
          },
          {
            title: 'Valor Taxa R$', dataIndex: 'ValorTaxa', key: 'ValorTaxa', position: 7, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 75,
          },
          {
            title: 'Valor Total R$', dataIndex: 'valorTotal', key: 'ValorTotal', position: 8, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 80,
          },
          {
            title: 'Conta', dataIndex: 'descricaoConta', key: 'Conta', position: 12, visible: true, ellipsis: true, width: 150,
          },
        ];
        // Colunas - Movimentos da Baixa
        antecipacaoTitulosBase.colunasMovimentos = [
          {
            title: 'Conta', dataIndex: 'descricaoConta', key: 'Conta', position: 1, visible: true, ellipsis: true, width: 100,
          },
          {
            title: 'Data', dataIndex: 'dataMovimento', key: 'DataMovimento', position: 2, visible: true, ordering: false, customRenderRow: ECustomRenderRow.MascararData, width: 35, align: 'center',
          },
          {
            title: 'Tipo', dataIndex: 'descricaoTipoMovimento', key: 'DescricaoTipoMovimento', position: 3, visible: true, width: 40, ellipsis: true,
          },
          {
            title: 'Identificador', dataIndex: 'identificadorMovimento', key: 'IdentificadorMovimento', position: 4, visible: true, ellipsis: true, width: 75,
          },
          {
            title: 'Descrição', dataIndex: 'descricao', key: 'DescricaoMovimento', position: 5, visible: true, ellipsis: true, width: 90,
          },
          {
            title: 'Valor R$', dataIndex: 'valor', key: 'ValorMovimento', position: 6, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 65,
          },
          {
            title: 'Influência Saldo', dataIndex: 'influenciaSaldo', key: 'InfluenciaSaldo', position: 7, visible: true, ordering: false, width: 40, align: 'center',
          },
        ];
      }
    }

    const mudarSelecao = (selectedRowKeys: any) => {
      gradeBase.codigosSelecionados = selectedRowKeys;
    };

    function limparTela() {
      limparTelaBase();
      gradeBase.codigosSelecionados = [] as number[];
    }

    async function obterValoresParametros() {
      antecipacaoTitulosBase.carregandoParametros = true;
      antecipacaoTitulosBase.alteracaoPercentuais = await servicoAntecipacaoTitulo.validarAlteracaoPercentuais(antecipacaoTitulosBase.empresaSelecionada);
      const categoriaPadraoTaxa = await servicoParametros.obterValor(antecipacaoTitulosBase.empresaSelecionada, 'PAR_FIN_RECEBER_CATEGORIA_PADRAO_TAXAS');
      if (categoriaPadraoTaxa !== undefined) {
        if (UtilitarioGeral.validaCodigo(categoriaPadraoTaxa.valor)) {
          antecipacaoTitulosBase.categoriaPadraoTaxas = Number(categoriaPadraoTaxa.valor);
        }
      }
      antecipacaoTitulosBase.percentualTaxa = 0;
      antecipacaoTitulosBase.carregandoParametros = false;
    }

    async function atualizarSelecaoEmpresas() {
      telaBase.empresasSelecionadas = [] as number[];
      telaBase.empresasSelecionadas.push(antecipacaoTitulosBase.empresaSelecionada);
      verificaAutorizacao(antecipacaoTitulosBase.empresaSelecionada, telaBase.identificadorPermissao, true);
      antecipacaoTitulosBase.categoriaPadraoTaxas = 0;
      antecipacaoTitulosBase.dataAntecipacao = await servicoSistema.obterDataAtual();
      antecipacaoTitulosBase.contaPadrao = await servicoConta.obterCodigoContaPadrao(antecipacaoTitulosBase.empresaSelecionada);
      limparTela();
      obterValoresParametros();
    }

    function preencherPessoaPadrao() {
      if (antecipacaoTitulosBase.titulosAntecipados.every((t) => t.codigoPessoa === antecipacaoTitulosBase.titulosAntecipados[0].codigoPessoa)) {
        antecipacaoTitulosBase.pessoaPadrao = antecipacaoTitulosBase.titulosAntecipados[0].codigoPessoa;
      } else {
        antecipacaoTitulosBase.pessoaPadrao = 0;
      }
    }

    function buscarTitulos() {
      antecipacaoTitulosBase.exibirBuscaTitulos = true;
    }

    async function concluirAntecipacao() {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      if (antecipacaoTitulosBase.titulosAntecipados.length === 0) {
        retorno.mensagem = 'Nenhum título selecionado para antecipação!';
        retorno.status = EStatusRetornoRequisicao.Alerta;
        apresentarRetornoRequisicao(retorno);
        return;
      }

      apresentarBarraProgresso();
      const antecipacao = obterObjetoAntecipacao();

      retorno = await servicoAntecipacaoTitulo.anteciparTitulos(antecipacao);
      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        limparTela();

        if (UtilitarioGeral.objetoValido(storeTitulosFinanceiro) && UtilitarioGeral.valorValido(storeTitulosFinanceiro.rotaRecursoOrigem)) {
          storeTitulosFinanceiro.token = UtilitarioGeral.gerarTokenUnico();
          storeTitulosFinanceiro.titulos = [];
          storeFinanceiro.mutations.adicionarTituloStore(storeTitulosFinanceiro);
          router.push({ name: storeTitulosFinanceiro.rotaRecursoOrigem, query: { token: storeTitulosFinanceiro.token } });
          return;
        }

        if (UtilitarioGeral.objetoValido(comunicacaoFinanceiro) && UtilitarioGeral.valorValido(comunicacaoFinanceiro.redirecionarAposConcluir)) {
          router.push({ name: comunicacaoFinanceiro.redirecionarAposConcluir, query: { token: comunicacaoFinanceiro.token } });
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    function confirmacaoLimparTela() {
      Modal.confirm({
        title: 'Atenção',
        content: 'Tem certeza que deseja limpar a tela de baixa?',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: () => {
          limparTela();
        },
      });
    }

    async function confirmacaoAntecipacao() {
      Modal.confirm({
        title: 'Concluir Antecipação',
        content: 'Tem certeza que deseja concluir a antecipação?',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          await concluirAntecipacao();
        },
      });
    }

    onBeforeMount(async () => {
      telaBase.carregando = true;
      telaBase.identificadorRecurso = antecipacaoTitulosBase.identificadorRecurso;
      telaBase.identificadorPermissao = antecipacaoTitulosBase.identificadorPermissao;
      antecipacaoTitulosBase.empresaSelecionada = storeSistema.getters.codigoEmpresaOperacao();
      antecipacaoTitulosBase.movimentosFinanceiros = [];
      antecipacaoTitulosBase.lancamentosContaNormais = [];

      preencherColunas();
      const tokenStore = String(route.query.token);
      if (UtilitarioGeral.valorValido(tokenStore)) {
        listaTitulosSelecionados = storeFinanceiro.getters.obterTitulos(tokenStore);
        if (UtilitarioGeral.validaLista(listaTitulosSelecionados)) {
          antecipacaoTitulosBase.empresaSelecionada = listaTitulosSelecionados[0].codigoEmpresa;
          antecipacaoTitulosBase.passoAtivo = '1';
          storeTitulosFinanceiro = UtilitarioGeral.instanciaObjetoLocal(storeFinanceiro.getters.obterTituloFinanceiroStore(tokenStore));
          storeFinanceiro.mutations.removerTitulos(tokenStore);
        }

        comunicacaoFinanceiro = storeFinanceiro.getters.obterComunicacao(tokenStore);
        if (UtilitarioGeral.objetoValido(comunicacaoFinanceiro) && UtilitarioGeral.validaCodigo(comunicacaoFinanceiro.empresa)) {
          antecipacaoTitulosBase.empresaSelecionada = comunicacaoFinanceiro.empresa;
        }
      }
      await obterPermissoes(ETipoPermissao.Autorizacoes);
      await preencherEmpresasDisponiveis();
      verificaAutorizacao(antecipacaoTitulosBase.empresaSelecionada, telaBase.identificadorPermissao, true);
      await obterValoresParametros();
      antecipacaoTitulosBase.contaPadrao = await servicoConta.obterCodigoContaPadrao(antecipacaoTitulosBase.empresaSelecionada);
      antecipacaoTitulosBase.dataAntecipacao = await servicoSistema.obterDataAtual();
      if (UtilitarioGeral.validaLista(listaTitulosSelecionados)) {
        populartitulosAntecipados(listaTitulosSelecionados);
      }
      const recebimentoNormal = { codigoConta: antecipacaoTitulosBase.contaPadrao, dataBaixa: antecipacaoTitulosBase.dataAntecipacao, valor: 0 } as IDTOLancamentoBaixaConta;
      antecipacaoTitulosBase.lancamentosContaNormais.push(recebimentoNormal);
      telaBase.carregando = false;
    });

    async function verificarPassos() {
      if (antecipacaoTitulosBase.passoAtivo === '3') {
        await prepararPreviaAntecipar();
        if (antecipacaoTitulosBase.movimentosInvalidos) {
          antecipacaoTitulosBase.passoAtivo = '1';
        }
      }
    }
    function verificarConclusaoAntecipacao(): boolean {
      if (antecipacaoTitulosBase.movimentosInvalidos) return false;

      if (!UtilitarioGeral.validaLista(antecipacaoTitulosBase.movimentosFinanceiros)) {
        return false;
      }

      if (antecipacaoTitulosBase.passoAtivo === '3') {
        console.log('Total Final: ', antecipacaoTitulosBase.totalFinal, 'Total Movimentos: ', antecipacaoTitulosBase.totalMovimentoFinal);
        if (antecipacaoTitulosBase.totalFinal === antecipacaoTitulosBase.totalMovimentoFinal) {
          return true;
        }
      }

      return false;
    }

    function removerTodosTitulos() {
      antecipacaoTitulosBase.titulosAntecipados = [] as IDTOTituloFinanceiroPendenteAntecipar[];
      calcularValores(false);
      prepararPreviaAntecipar();
    }

    function removerTitulosMarcados() {
      gradeBase.codigosSelecionados.forEach((t) => {
        const indexTitulo = antecipacaoTitulosBase.titulosAntecipados.findIndex((ts) => ts.codigoTituloFinanceiro === t);
        antecipacaoTitulosBase.titulosAntecipados.splice(indexTitulo, 1);
      });
      gradeBase.codigosSelecionados = [];
      calcularValores(false);
      prepararPreviaAntecipar();
      preencherPessoaPadrao();
    }

    return {
      antecipacaoTitulosBase,
      props,
      modalBase,
      window,
      UtilitarioData,
      storeSistema,
      ETipoTituloFinanceiro,
      telaBase,
      gradeBase,
      ordenacaoAtiva,
      atualizarSelecaoEmpresas,
      adicionarAtalho,
      removerAtalho,
      buscarTitulos,
      concluirAntecipacao,
      removerTodosTitulos,
      populartitulosAntecipados,
      UtilitarioMascara,
      removerTitulosMarcados,
      calcularValores,
      ECustomRenderRow,
      obterDescricaoConta,
      obterDescricaoInfluenciaSaldo,
      confirmacaoAntecipacao,
      confirmacaoLimparTela,
      UtilitarioFinanceiro,
      verificacaoCalculo,
      mudarSelecao,
      valorTaxaDigitado,
      verificarPassos,
      verificarConclusaoAntecipacao,
      verificacaoAlteracaoData,
      proximoElemento,
      elementoAnterior,
      elementoAcima,
      elementoAbaixo,
    };
  },
});
