import { reactive } from 'vue';
import { IColumn } from '@/core/models/AntDesign/IColumn';
import { IDTOClienteFinanceiro } from '@/models/DTO/Cadastros/Pessoas/Clientes/IDTOClienteFinanceiro';
import { IDTOBaixaTitulosPessoaTotal } from '@/models/DTO/Financeiro/BaixasTitulosFinanceiros/IDTOBaixaTitulosPessoaTotal';
import { IDTOLancamentoBaixaConta } from '@/models/DTO/Financeiro/BaixasTitulosFinanceiros/IDTOLancamentoBaixaConta';
import { IDTOTituloFinanceiro } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiro';
import UtilitarioFinanceiro from '@/core/utilitarios/UtilitarioFinanceiro';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { EStatusTituloFinanceiro } from '@/models/Enumeradores/Financeiro/EStatusTituloFinanceiro';
import { ETipoMovimentoFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoMovimentoFinanceiro';
import storeSistema from '@/store/storeSistema';
import { IMovimentoFinanceiro } from '@/models/Entidades/Financeiro/MovimentosFinanceiros/IMovimentoFinanceiro';
import ServicoBaixaTitulosReceber from '@/servicos/Financeiro/ServicoBaixaTitulosReceber';
import { useTelaBase } from '@/core/composables/TelaBase';
import { IDTOTituloFinanceiroDetalhamento } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroDetalhamento';
import { IDTOTituloFinanceiroObterDetalhamentoVariosTitulos } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroObterDetalhamentoVariosTitulos';
import ServicoTituloFinanceiroReceber from '@/servicos/Financeiro/ServicoTituloFinanceiroReceber';
import { IAntecipacaoTitulos, IAntecipacaoTitulosMovimentoFinanceiro, IAntecipacaoTitulosTituloFinanceiro } from '@/models/Entidades/Financeiro/AntecipacaoTitulos/IAntecipacaoTitulosFinanceiros';
import { IDTOTituloFinanceiroPendenteAntecipar } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroPendenteAntecipar';

export interface IAlertaAtencipacao {
    titulo: string;
    mensagem: string;
    tipo: string;
    visivel: boolean;
  }

export interface IAntecipacaoTitulosBase {
    identificadorRecurso: string;
    identificadorPermissao: string;
    codigoTituloPesquisa: number;
    contaPadrao: number;
    alertaBaixa: IAlertaAtencipacao;
    titulosAntecipados: IDTOTituloFinanceiroPendenteAntecipar[];
    titulosLancados: IDTOTituloFinanceiro[];
    totalTitulosLancados: number;
    lancamentosContaTitulosLancados: IDTOLancamentoBaixaConta[];
    lancamentosContaNormais: IDTOLancamentoBaixaConta[];
    totalLancamentosContaNormais: number;
    dadosFinanceirosClientes: IDTOClienteFinanceiro[];
    colunasMovimentos: IColumn[];
    movimentosFinanceiros: IAntecipacaoTitulosMovimentoFinanceiro[];
    empresaSelecionada: number;
    categoriaPadraoTaxas: number;
    dataAntecipacao: string;
    calcularValoresTitulos: boolean;
    diasUteis: boolean;
    pessoaPadrao: number;
    pessoasTitulos: IDTOBaixaTitulosPessoaTotal[];
    valorTaxa: number;
    percentualTaxa: number;
    totalTitulos: number;
    totalTaxas: number;
    totalFinal: number;
    totalTaxasMovimentos: number;
    totalMovimentos: number;
    totalAntecipacao: number;
    totalMovimentoFinal: number;
    exibirBuscaTitulos: boolean;
    carregandoParametros: boolean;
    alteracaoPercentuais: boolean;
    passoAtivo: string;
    detalhesPagamentos: IDTOTituloFinanceiroDetalhamento[];
    movimentosInvalidos: boolean;
}
export interface IRetornoAntecipacaoTitulosBase{
    antecipacaoTitulosBase: IAntecipacaoTitulosBase;
    limparTelaBase(): void;
    zerarValores(): void;
    calcularPercentuais(): void;
    calcularTaxas(): void;
    verificacaoCalculo(): void;
    verificacaoAlteracaoData(): void;
    verificacaoAlertaAntecipacao(): void;
    calcularTotalizadoresMovimentos(): void;
    calcularValores(revisarTitulos: boolean): void;
    obterDadosFinanceirosClientes(): void;
    populartitulosAntecipados(titulos: IDTOTituloFinanceiro[]): void;
    obterDescricaoConta(codigoConta:number): string;
    obterDescricaoInfluenciaSaldo(influenciaSaldo:boolean):string;
    obterTitulosAntecipado(): void;
    obterObjetoAntecipacao(): IAntecipacaoTitulos;
    valorTaxaDigitado(index: number): void;
    executaAntecipacao(lancamentosConta: IDTOLancamentoBaixaConta[], influenciaSaldo: boolean, descricaoPrincipal: string): void;
    prepararPreviaAntecipar(): void;
}

export function useAntecipacaoTitulosBase():IRetornoAntecipacaoTitulosBase {
  const antecipacaoTitulosBase = reactive({
    identificadorRecurso: '',
    identificadorPermissao: '',
    tipoTituloBaixa: 0,
    codigoTituloPesquisa: 0,
    contaPadrao: 0,
    alertaBaixa: {} as IAlertaAtencipacao,
    titulosAntecipados: [] as IDTOTituloFinanceiroPendenteAntecipar[],
    titulosLancados: [] as IDTOTituloFinanceiro[],
    totalTitulosLancados: 0,
    lancamentosContaTitulosLancados: [] as IDTOLancamentoBaixaConta[],
    lancamentosContaNormais: [] as IDTOLancamentoBaixaConta[],
    totalLancamentosContaNormais: 0,
    dadosFinanceirosClientes: [] as IDTOClienteFinanceiro[],
    colunasMovimentos: [] as IColumn[],
    movimentosFinanceiros: [] as IAntecipacaoTitulosMovimentoFinanceiro[],
    empresaSelecionada: 0,
    categoriaPadraoTaxas: 0,
    dataAntecipacao: '',
    calcularValoresTitulos: false,
    diasUteis: false,
    pessoaPadrao: 0,
    pessoasTitulos: [] as IDTOBaixaTitulosPessoaTotal[],
    valorTaxa: 0,
    percentualTaxa: 0,
    totalTitulos: 0,
    totalTaxas: 0,
    totalFinal: 0,
    totalTaxasMovimentos: 0,
    totalMovimentos: 0,
    totalAntecipacao: 0,
    totalMovimentoFinal: 0,
    exibirBuscaTitulos: false,
    buscarCreditos: false,
    carregandoParametros: false,
    alteracaoPercentuais: false,
    passoAtivo: '',
    detalhesPagamentos: [] as IDTOTituloFinanceiroDetalhamento[],
    movimentosInvalidos: false,
  });

  const { apresentarMensagemAlerta } = useTelaBase();
  const servicoTituloFinanceiroReceber = new ServicoTituloFinanceiroReceber();
  servicoTituloFinanceiroReceber.requisicaoSistema();

  let numeroMovimentoControle = 0;
  let totalFinalCalculoTaxas = 0;
  let debounceTaxas = 0;
  let movimentosFinanceiros:IAntecipacaoTitulosMovimentoFinanceiro[] = [];
  // const titulosOriginais:IDTOTituloFinanceiroPendenteAntecipar[] = [];
  let titulosParaAntecipar:IDTOTituloFinanceiroPendenteAntecipar[] = [];

  function limparTelaBase() {
    antecipacaoTitulosBase.diasUteis = false;
    antecipacaoTitulosBase.passoAtivo = '';
    antecipacaoTitulosBase.titulosAntecipados = [] as IDTOTituloFinanceiroPendenteAntecipar[];
    antecipacaoTitulosBase.movimentosFinanceiros = [] as IAntecipacaoTitulosMovimentoFinanceiro[];
    antecipacaoTitulosBase.titulosLancados = [] as IDTOTituloFinanceiro[];
    antecipacaoTitulosBase.dadosFinanceirosClientes = [] as IDTOClienteFinanceiro[];
    antecipacaoTitulosBase.titulosLancados = [] as IDTOTituloFinanceiro[];
    antecipacaoTitulosBase.totalTitulosLancados = 0;
    antecipacaoTitulosBase.lancamentosContaTitulosLancados = [] as IDTOLancamentoBaixaConta[];
    antecipacaoTitulosBase.lancamentosContaNormais = [] as IDTOLancamentoBaixaConta[];
    antecipacaoTitulosBase.totalLancamentosContaNormais = 0;
    antecipacaoTitulosBase.valorTaxa = 0;
    antecipacaoTitulosBase.totalTitulos = 0;
    antecipacaoTitulosBase.totalTaxas = 0;
    antecipacaoTitulosBase.totalFinal = 0;
    antecipacaoTitulosBase.totalTaxasMovimentos = 0;
    antecipacaoTitulosBase.totalMovimentos = 0;
    antecipacaoTitulosBase.totalAntecipacao = 0;
    antecipacaoTitulosBase.movimentosInvalidos = false;
    const recebimentoNormal = { codigoConta: antecipacaoTitulosBase.contaPadrao, dataBaixa: antecipacaoTitulosBase.dataAntecipacao, valor: 0 } as IDTOLancamentoBaixaConta;
    antecipacaoTitulosBase.lancamentosContaNormais.push(recebimentoNormal);
  }

  function zerarValores() {
    antecipacaoTitulosBase.totalTitulos = 0;
    antecipacaoTitulosBase.totalTaxas = 0;
    antecipacaoTitulosBase.totalAntecipacao = 0;
    totalFinalCalculoTaxas = 0;
  }

  function calcularPercentuais() {
    antecipacaoTitulosBase.pessoasTitulos = [];
    antecipacaoTitulosBase.titulosAntecipados.forEach((t, index) => {
      antecipacaoTitulosBase.titulosAntecipados[index].valorTaxa = 0;
      antecipacaoTitulosBase.titulosAntecipados[index].valorTaxa = UtilitarioFinanceiro.calcularDesconto(t.saldo, antecipacaoTitulosBase.percentualTaxa);
      antecipacaoTitulosBase.totalTaxas += antecipacaoTitulosBase.titulosAntecipados[index].valorTaxa;
      antecipacaoTitulosBase.titulosAntecipados[index].valorTotal = (antecipacaoTitulosBase.titulosAntecipados[index].saldo - antecipacaoTitulosBase.titulosAntecipados[index].valorTaxa);
      antecipacaoTitulosBase.totalTitulos += antecipacaoTitulosBase.titulosAntecipados[index].saldo;

      const indexPessoa = antecipacaoTitulosBase.pessoasTitulos.findIndex((c) => c.codigoPessoa === t.codigoPessoa);
      if (indexPessoa >= 0) {
        antecipacaoTitulosBase.pessoasTitulos[indexPessoa].totalTitulos += t.valorTotal;
      } else {
        const pessoasTitulo:IDTOBaixaTitulosPessoaTotal = { codigoPessoa: t.codigoPessoa, totalTitulos: t.valorTotal } as IDTOBaixaTitulosPessoaTotal;
        antecipacaoTitulosBase.pessoasTitulos.push(pessoasTitulo);
      }
    });
  }

  function calcularTaxas() {
    const valorNominal = UtilitarioFinanceiro.calcularAcrescimo(totalFinalCalculoTaxas, antecipacaoTitulosBase.valorTaxa);
    antecipacaoTitulosBase.totalTaxas = valorNominal;
  }

  function verificacaoCalculo() {
    if (antecipacaoTitulosBase.titulosAntecipados.length > 0) {
      antecipacaoTitulosBase.calcularValoresTitulos = true;
    }
  }

  function verificacaoAlteracaoData() {
    verificacaoCalculo();
    for (let index = 0; index < antecipacaoTitulosBase.lancamentosContaNormais.length; index += 1) {
      antecipacaoTitulosBase.lancamentosContaNormais[index].dataBaixa = antecipacaoTitulosBase.dataAntecipacao;
    }
    antecipacaoTitulosBase.passoAtivo = '';
  }

  function verificacaoAlertaAntecipacao() {
    if (antecipacaoTitulosBase.movimentosInvalidos) return;

    const totalAcerto = UtilitarioFinanceiro.valorDecimal2Casas(antecipacaoTitulosBase.totalTitulosLancados + antecipacaoTitulosBase.totalLancamentosContaNormais);
    const totalBaixa = UtilitarioFinanceiro.valorDecimal2Casas(antecipacaoTitulosBase.totalAntecipacao);
    antecipacaoTitulosBase.alertaBaixa.visivel = false;
    if (totalAcerto > 0 && totalAcerto === totalBaixa) {
      antecipacaoTitulosBase.alertaBaixa.tipo = 'success';
      antecipacaoTitulosBase.alertaBaixa.titulo = 'Você está realizando a antecipação dos títulos';
      antecipacaoTitulosBase.alertaBaixa.mensagem = antecipacaoTitulosBase.titulosAntecipados.length > 1 ? 'Todos os títulos selecionados serão antecipados.' : 'O título selecionado será antecipado.';
      antecipacaoTitulosBase.alertaBaixa.visivel = true;
    } else if (totalAcerto > 0 && totalAcerto < totalBaixa) {
      antecipacaoTitulosBase.movimentosInvalidos = true;
      antecipacaoTitulosBase.movimentosFinanceiros = [];
      apresentarMensagemAlerta('A antecipacão dos titulos não pode ser no valor inferior do total.');
      antecipacaoTitulosBase.passoAtivo = '1';
    } else if (totalAcerto > 0 && totalAcerto > totalBaixa) {
      antecipacaoTitulosBase.movimentosInvalidos = true;
      antecipacaoTitulosBase.movimentosFinanceiros = [];
      apresentarMensagemAlerta('A antecipacão dos titulos não pode ser no valor superior ao total');
      antecipacaoTitulosBase.passoAtivo = '1';
    }
  }

  function calcularTotalizadoresMovimentos() {
    antecipacaoTitulosBase.totalTaxasMovimentos = 0;
    antecipacaoTitulosBase.totalMovimentos = 0;
    antecipacaoTitulosBase.totalMovimentoFinal = 0;
    antecipacaoTitulosBase.totalMovimentoFinal = ((antecipacaoTitulosBase.totalMovimentos + antecipacaoTitulosBase.totalTaxasMovimentos));
    verificacaoAlertaAntecipacao();
  }

  function calcularValores(revisarTitulos: boolean) {
    zerarValores();
    calcularPercentuais();
    antecipacaoTitulosBase.totalFinal = antecipacaoTitulosBase.totalTitulos - antecipacaoTitulosBase.totalTaxas;
    antecipacaoTitulosBase.totalFinal = UtilitarioFinanceiro.valorDecimal2Casas(antecipacaoTitulosBase.totalTitulos - antecipacaoTitulosBase.totalTaxas);
    antecipacaoTitulosBase.totalAntecipacao = antecipacaoTitulosBase.totalFinal;
    antecipacaoTitulosBase.calcularValoresTitulos = false;
    if (revisarTitulos) { antecipacaoTitulosBase.passoAtivo = '1'; }
  }

  async function obterDadosFinanceirosClientes() {
    const pessoas = [] as number[];

    antecipacaoTitulosBase.titulosAntecipados.forEach((t) => {
      if (!pessoas.some((p) => p === t.codigoPessoa)) {
        pessoas.push(t.codigoPessoa);
      }
    });
    antecipacaoTitulosBase.dadosFinanceirosClientes = await new ServicoBaixaTitulosReceber().obterDadosFinanceirosClientes(pessoas, antecipacaoTitulosBase.empresaSelecionada);
  }

  async function populartitulosAntecipados(titulos: IDTOTituloFinanceiro[]) {
    let titulosAdicionados = false;
    const titulosAntecipados:IDTOTituloFinanceiroPendenteAntecipar[] = UtilitarioGeral.instanciaObjetoLocal(antecipacaoTitulosBase.titulosAntecipados);
    titulos.forEach((t) => {
      const index = titulosAntecipados.findIndex((ts) => ts.codigoTituloFinanceiro === t.codigo);
      if (index === -1) {
        if (t.status === EStatusTituloFinanceiro.Pendente || t.status === EStatusTituloFinanceiro.BaixadoParcialmente) {
          const tituloBaixa = {} as IDTOTituloFinanceiroPendenteAntecipar;
          tituloBaixa.codigoAntecipacaoTitulos = 0;
          tituloBaixa.codigoMovimentoFinanceiro = 0;
          tituloBaixa.codigoEmpresa = t.codigoEmpresa;
          tituloBaixa.codigoTituloFinanceiro = t.codigo;
          tituloBaixa.codigoConta = t.codigoConta;
          tituloBaixa.codigoPlanoContaCategoria = t.codigoPlanoContaCategoria;
          tituloBaixa.codigoPessoa = t.codigoPessoa;
          tituloBaixa.codigoTipoDocumentoFinanceiro = t.codigoTipoDocumentoFinanceiro;
          tituloBaixa.dataCriacao = t.dataCriacao;
          tituloBaixa.dataVencimento = t.dataVencimento;
          tituloBaixa.descricaoConta = t.descricaoConta;
          tituloBaixa.descricaoTipo = t.descricaoTipo;
          tituloBaixa.descricaoTipoDocumentoFinanceiro = t.descricaoTipoDocumentoFinanceiro;
          tituloBaixa.siglaTipoDocumentoFinanceiro = t.siglaTipoDocumentoFinanceiro;
          tituloBaixa.nomePessoa = t.nomePessoa;
          tituloBaixa.nomePlanoContaCategoria = t.nomePlanoContaCategoria;
          tituloBaixa.numeroTitulo = t.numeroTitulo;
          tituloBaixa.observacoes = t.observacoes;
          tituloBaixa.saldo = t.saldo;
          tituloBaixa.status = t.status;
          tituloBaixa.tipo = t.tipo;
          tituloBaixa.valorTaxa = 0;
          tituloBaixa.valorAntecipado = 0;
          tituloBaixa.valorUtilizado = 0;
          tituloBaixa.definidoValorTaxaManual = false;
          titulosAdicionados = true;
          titulosAntecipados.push(tituloBaixa);
        }
      }
    });
    if (titulosAdicionados) {
      antecipacaoTitulosBase.titulosAntecipados = titulosAntecipados;
      calcularValores(false);
    }
  }

  function obterDescricaoConta(codigoConta:number) {
    if (UtilitarioGeral.validaLista(antecipacaoTitulosBase.lancamentosContaNormais)) {
      const conta = antecipacaoTitulosBase.lancamentosContaNormais.find((c) => c.codigoConta === codigoConta);
      if (conta !== undefined) {
        return conta.descricaoConta;
      }
    }

    if (UtilitarioGeral.validaLista(antecipacaoTitulosBase.lancamentosContaTitulosLancados)) {
      const conta = antecipacaoTitulosBase.lancamentosContaTitulosLancados.find((c) => c.codigoConta === codigoConta);
      if (conta !== undefined) {
        return conta.descricaoConta;
      }
    }

    return '';
  }

  function obterDescricaoInfluenciaSaldo(influenciaSaldo:boolean) {
    return influenciaSaldo ? 'Sim' : 'Não';
  }

  function criarTituloAntecipado(titulo:IDTOTituloFinanceiroPendenteAntecipar):IAntecipacaoTitulosTituloFinanceiro {
    const tituloAntecipado = {} as IAntecipacaoTitulosTituloFinanceiro;
    tituloAntecipado.codigo = 0;
    tituloAntecipado.codigoBAntecipacaoTitulos = 0;
    tituloAntecipado.codigoTituloFinanceiro = titulo.codigoTituloFinanceiro;
    tituloAntecipado.valorAntecipado = titulo.valorTotal;
    tituloAntecipado.valorTaxa = titulo.valorTaxa;
    return tituloAntecipado;
  }

  function obterTitulosAntecipado() {
    const titulos = [] as IAntecipacaoTitulosTituloFinanceiro[];

    if (UtilitarioGeral.validaLista(antecipacaoTitulosBase.titulosAntecipados)) {
      antecipacaoTitulosBase.titulosAntecipados.forEach((t) => {
        titulos.push(criarTituloAntecipado(t));
      });
    }

    return titulos;
  }

  function obterObjetoAntecipacao(): IAntecipacaoTitulos {
    const antecipacao = {} as IAntecipacaoTitulos;
    antecipacao.codigoEmpresa = antecipacaoTitulosBase.empresaSelecionada;
    antecipacao.codigoUsuario = storeSistema.getters.codigoUsuarioAutenticado();
    antecipacao.dataAntecipacao = antecipacaoTitulosBase.dataAntecipacao;
    antecipacao.percentualTaxa = antecipacaoTitulosBase.percentualTaxa;
    antecipacao.qtdTitulos = antecipacaoTitulosBase.titulosAntecipados.length;
    antecipacao.totalTitulos = antecipacaoTitulosBase.totalTitulos;
    antecipacao.totalTaxas = antecipacaoTitulosBase.totalTaxasMovimentos;
    antecipacao.totalAntecipado = antecipacaoTitulosBase.totalMovimentoFinal;
    antecipacao.movimentosFinanceiros = antecipacaoTitulosBase.movimentosFinanceiros;
    antecipacao.titulos = obterTitulosAntecipado();
    return antecipacao;
  }

  function valorTaxaDigitado(index: number) {
    clearTimeout(debounceTaxas);
    debounceTaxas = setTimeout(async () => {
      antecipacaoTitulosBase.titulosAntecipados[index].definidoValorTaxaManual = true;
      calcularValores(false);
    }, 800);
  }

  function criarMovimentoFinanceiro(codigoConta: number,
    tipoMovimento:ETipoMovimentoFinanceiro,
    descricaoMovimento: string,
    complementoIdentificador:string,
    influenciaSaldo: boolean,
    valor:number,
    codigoPlanoContaCategoria: number,
    tituloFinanceiro:IDTOTituloFinanceiroPendenteAntecipar): IAntecipacaoTitulosMovimentoFinanceiro[] {
    const movimentosFinanceiros: IAntecipacaoTitulosMovimentoFinanceiro[] = [];
    numeroMovimentoControle += 1;
    const movimento = {} as IAntecipacaoTitulosMovimentoFinanceiro;
    movimento.codigoTituloFinanceiro = tituloFinanceiro.codigoTituloFinanceiro;
    movimento.movimentoFinanceiro = {} as IMovimentoFinanceiro;
    movimento.movimentoFinanceiro.codigoConta = codigoConta;
    movimento.movimentoFinanceiro.codigoPlanoContaCategoria = codigoPlanoContaCategoria;
    movimento.movimentoFinanceiro.codigoEmpresa = tituloFinanceiro.codigoEmpresa;
    movimento.movimentoFinanceiro.codigoPessoa = tituloFinanceiro.codigoPessoa;
    movimento.movimentoFinanceiro.codigoTipoDocumentoFinanceiro = tituloFinanceiro.codigoTipoDocumentoFinanceiro;
    movimento.movimentoFinanceiro.conciliado = false;
    movimento.movimentoFinanceiro.influenciaSaldo = influenciaSaldo;
    movimento.movimentoFinanceiro.dataMovimento = antecipacaoTitulosBase.dataAntecipacao;
    movimento.movimentoFinanceiro.descricao = descricaoMovimento;
    movimento.movimentoFinanceiro.identificadorMovimento = `${tituloFinanceiro.numeroTitulo}-${complementoIdentificador}-M${numeroMovimentoControle}`;
    movimento.movimentoFinanceiro.marcadores = [];
    movimento.movimentoFinanceiro.recursoOrigem = antecipacaoTitulosBase.identificadorRecurso;
    movimento.movimentoFinanceiro.tipoMovimento = tipoMovimento;
    movimento.movimentoFinanceiro.valor = valor;
    movimento.movimentoFinanceiro.anexos = [];
    // if (tipoMovimento === ETipoMovimentoFinanceiro.Pagamento) {
    //  antecipacaoTitulosBase.totalMovimentoFinal -= valor;
    // } else {
    //  antecipacaoTitulosBase.totalMovimentoFinal += valor;
    // }
    movimentosFinanceiros.push(movimento);

    return movimentosFinanceiros;
  }

  function executaAntecipacao(lancamentosConta: IDTOLancamentoBaixaConta[], influenciaSaldo: boolean, descricaoPrincipal: string) {
    antecipacaoTitulosBase.movimentosInvalidos = false;

    lancamentosConta.forEach((detalheLancamentoConta) => {
      if (antecipacaoTitulosBase.movimentosInvalidos) {
        movimentosFinanceiros = [];
        return;
      }
      let valorDisponivelAntecipar = UtilitarioFinanceiro.valorDecimal2Casas(detalheLancamentoConta.valor);
      for (let index = 0; index < titulosParaAntecipar.length; index += 1) {
        const criarMovimento = true;
        if (valorDisponivelAntecipar > 0) {
          if (criarMovimento && titulosParaAntecipar[index].saldo > 0) {
            const valorPendente = UtilitarioFinanceiro.valorDecimal2Casas(((titulosParaAntecipar[index].saldo) - titulosParaAntecipar[index].valorTaxa));
            if (valorPendente <= valorDisponivelAntecipar) {
              titulosParaAntecipar[index].valorAntecipado = titulosParaAntecipar[index].saldo;
              valorDisponivelAntecipar -= UtilitarioFinanceiro.valorDecimal2Casas((titulosParaAntecipar[index].saldo - titulosParaAntecipar[index].valorTaxa));
              movimentosFinanceiros.push(...criarMovimentoFinanceiro(detalheLancamentoConta.codigoConta, ETipoMovimentoFinanceiro.Recebimento, descricaoPrincipal, 'ANTECIPACAO', influenciaSaldo, titulosParaAntecipar[index].saldo, titulosParaAntecipar[index].codigoPlanoContaCategoria, titulosParaAntecipar[index]));
              antecipacaoTitulosBase.totalMovimentoFinal += titulosParaAntecipar[index].saldo;
              if (antecipacaoTitulosBase.movimentosInvalidos) {
                movimentosFinanceiros = [];
                break;
              }
              if (titulosParaAntecipar[index].valorTaxa > 0) {
                movimentosFinanceiros.push(...criarMovimentoFinanceiro(detalheLancamentoConta.codigoConta, ETipoMovimentoFinanceiro.Pagamento, 'TAXA', 'TAXA', influenciaSaldo, titulosParaAntecipar[index].valorTaxa, antecipacaoTitulosBase.categoriaPadraoTaxas, titulosParaAntecipar[index]));
                antecipacaoTitulosBase.totalMovimentoFinal -= titulosParaAntecipar[index].valorTaxa;
                titulosParaAntecipar[index].valorTaxa = 0;
              }
              titulosParaAntecipar[index].saldo = 0;
            }
          }
        }
      }
    });
  }

  async function prepararPreviaAntecipar() {
    movimentosFinanceiros = [];
    antecipacaoTitulosBase.movimentosFinanceiros = [];
    antecipacaoTitulosBase.lancamentosContaTitulosLancados = [];
    antecipacaoTitulosBase.totalMovimentoFinal = 0;

    const dados:IDTOTituloFinanceiroObterDetalhamentoVariosTitulos = {} as IDTOTituloFinanceiroObterDetalhamentoVariosTitulos;
    dados.empresas = antecipacaoTitulosBase.titulosAntecipados.map((c) => c.codigoEmpresa);
    dados.tipoTituloFinanceiro = antecipacaoTitulosBase.tipoTituloBaixa;
    dados.codigosTituloFinanceiros = antecipacaoTitulosBase.titulosAntecipados.map((c) => c.codigoTituloFinanceiro);

    antecipacaoTitulosBase.detalhesPagamentos = await servicoTituloFinanceiroReceber.obterDetalhamentosVariosTitulos(dados);

    numeroMovimentoControle = 0;
    if (antecipacaoTitulosBase.totalTaxas > 0 && !UtilitarioGeral.validaCodigo(antecipacaoTitulosBase.categoriaPadraoTaxas)) {
      apresentarMensagemAlerta('Para visualizar a prévia da baixa é necessário informar a Categoria Padrão de Taxas!');
      antecipacaoTitulosBase.passoAtivo = '1';
      return;
    }

    titulosParaAntecipar = UtilitarioGeral.instanciaObjetoLocal(antecipacaoTitulosBase.titulosAntecipados);

    if (antecipacaoTitulosBase.totalTitulosLancados > 0 || antecipacaoTitulosBase.totalLancamentosContaNormais > 0) {
      if (UtilitarioGeral.validaLista(antecipacaoTitulosBase.titulosLancados)) {
        antecipacaoTitulosBase.titulosLancados.forEach((tituloFinanceiro) => {
          const index = antecipacaoTitulosBase.lancamentosContaTitulosLancados.findIndex((c) => c.codigoConta === tituloFinanceiro.codigoConta);
          if (index >= 0) {
            antecipacaoTitulosBase.lancamentosContaTitulosLancados[index].valor += tituloFinanceiro.saldo;
          } else {
            const lancamentoContaNovosTitulos: IDTOLancamentoBaixaConta = {
              codigoConta: tituloFinanceiro.codigoConta, descricaoConta: tituloFinanceiro.descricaoConta, dataBaixa: antecipacaoTitulosBase.dataAntecipacao, codigoPessoa: 0, valor: tituloFinanceiro.saldo,
            };
            antecipacaoTitulosBase.lancamentosContaTitulosLancados.push(lancamentoContaNovosTitulos);
          }
        });
        executaAntecipacao(antecipacaoTitulosBase.lancamentosContaTitulosLancados, false, 'ANTECIPAÇÃO DE TITULO');
      }

      if (UtilitarioGeral.validaLista(antecipacaoTitulosBase.lancamentosContaNormais)) {
        executaAntecipacao(antecipacaoTitulosBase.lancamentosContaNormais, true, 'ANTECIPACAO DE TITULO');
      }
    }
    antecipacaoTitulosBase.movimentosFinanceiros = movimentosFinanceiros;
  }
  return {
    antecipacaoTitulosBase,
    limparTelaBase,
    zerarValores,
    calcularPercentuais,
    calcularTaxas,
    verificacaoCalculo,
    verificacaoAlteracaoData,
    verificacaoAlertaAntecipacao,
    calcularTotalizadoresMovimentos,
    calcularValores,
    obterDadosFinanceirosClientes,
    populartitulosAntecipados,
    obterDescricaoConta,
    obterDescricaoInfluenciaSaldo,
    obterTitulosAntecipado,
    obterObjetoAntecipacao,
    valorTaxaDigitado,
    executaAntecipacao,
    prepararPreviaAntecipar,
  };
}
