
import { defineComponent, reactive, watch } from 'vue';
import { Modal } from 'ant-design-vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useTituloFinanceiroBase } from '@/composables/Financeiro/TituloFinanceiroBase';
import { useGradeBase } from '@/core/composables/GradeBase';
import Card from '@/core/components/Tela/Card.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { EStatusTituloFinanceiro } from '@/models/Enumeradores/Financeiro/EStatusTituloFinanceiro';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import SelecionarCategoriaPlanoConta from '@/components/Cadastros/PlanosContas/SelecionarCategoriaPlanoConta.vue';
import SelecionarConta from '@/components/Cadastros/Financeiro/SelecionarConta.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import { IDTOTituloFinanceiro } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiro';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { useModalBase } from '@/core/composables/ModalBase';
import ServicoTituloFinanceiroPagar from '@/servicos/Financeiro/ServicoTituloFinanceiroPagar';
import storeSistema from '@/store/storeSistema';
import Icone from '@/core/components/Icone.vue';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { ETipoTituloFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoTituloFinanceiro';
import ServicoTituloFinanceiroReceber from '@/servicos/Financeiro/ServicoTituloFinanceiroReceber';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { ITituloFinanceiro } from '@/models/Entidades/Financeiro/TitulosFinanceiros/ITituloFinanceiro';

export default defineComponent({
  name: 'AlteracaoFinanceiroMassa',
  props: {
    visivel: {
      type: Boolean,
    },
    titulos: {
      type: Object as () => IDTOTituloFinanceiro[],
      required: true,
    },
    empresa: {
      type: Number,
      required: true,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
    tipo: {
      type: Object as () => ETipoTituloFinanceiro,
      required: true,
    },
  },
  components: {
    Card,
    Icone,
    SelecionarData,
    SelecionarCategoriaPlanoConta,
    SelecionarConta,
    CampoNumerico,
    MensagemSemDados,
    RequisicaoModal,
  },
  emits: ['update:visivel', 'confirmacao'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, apresentarMensagemSucesso, preencherPermissoesDados, filtrarPermissaoDadosUsuario,
    } = useTelaBase();
    const { gradeBase } = useGradeBase();
    const {
      tituloFinanceiroBase, obterClasseStatus, obtemValorTitulo,
    } = useTituloFinanceiroBase(props);
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);
    const servicoTituloFinanceiroPagar = new ServicoTituloFinanceiroPagar();
    const servicoTituloFinanceiroReceber = new ServicoTituloFinanceiroReceber();

    const state = reactive({
      empresaSelecionada: 0,
      titulosAlterados: [] as ITituloFinanceiro[],
      dataVencimento: UtilitarioData.obterDataAtual(),
      alterouVencimento: false,
      dataCompetencia: UtilitarioData.obterDataAtual(),
      alterouCompetencia: false,
      valor: 0.0,
      codigoConta: 0,
      codigoPlanoContaCategoria: 0,
      titulosFinanceirosDTO: [] as IDTOTituloFinanceiro[],
      saldo: 0.0,
    });

    function preencherColunas() {
      if (gradeBase.layoutMobile) {
        // Colunas - Títulos selecionados Baixa
        gradeBase.colunas = [
          {
            title: 'Código', dataIndex: 'codigoTituloFinanceiro', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'Título', dataIndex: 'titulo', key: 'TituloFinanceiro', position: 2, visible: true,
          },
        ];
      } else {
        // Colunas - Títulos selecionados Baixa
        gradeBase.colunas = [
          {
            title: 'Código', dataIndex: 'codigoTituloFinanceiro', key: 'Codigo', position: 0, visible: false,
          },
          {
            title: 'PGTO', dataIndex: 'tipoDocumentoFinanceiro', key: 'TipoDocumentoFinanceiro', position: 1, visible: true, width: 40, ellipsis: true, fixed: 'left',
          },
          {
            title: 'N.Identificação', dataIndex: 'numeroTitulo', key: 'NumeroTitulo', position: 2, visible: true, ellipsis: true, width: 75, fixed: 'left',
          },
          {
            title: 'Pessoa', dataIndex: 'nomePessoa', key: 'NomePessoa', position: 3, visible: true, ellipsis: true, width: 150, fixed: 'left',
          },
          {
            title: 'Competencia', dataIndex: 'dataCompetencia', key: 'DataCompetencia', position: 4, visible: true, ordering: false, customRenderRow: ECustomRenderRow.MascararData, width: 60, align: 'center', fixed: 'left',
          },
          {
            title: 'Vencimento', dataIndex: 'dataVencimento', key: 'DataVencimento', position: 5, visible: true, ordering: false, customRenderRow: ECustomRenderRow.MascararData, width: 60, align: 'center', fixed: 'left',
          },
          {
            title: 'Conta', dataIndex: 'descricaoConta', key: 'Conta', position: 6, visible: true, ellipsis: true, width: 150,
          },
          {
            title: 'Categoria', dataIndex: 'nomePlanoContaCategoria', key: 'NomePlanoContaCategoria', position: 7, visible: true, ellipsis: true, width: 150,
          },
          {
            title: 'Valor Total R$', dataIndex: 'valorTotal', key: 'ValorTotal', position: 8, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 80,
          },
        ];
      }
    }

    async function buscarTitulos() {
      for (let i = 0; i < props.titulos.length; i += 1) {
        const titulo = await servicoTituloFinanceiroPagar.obter(props.titulos[i].codigo, props.operacao.empresaSelecionada);
        state.titulosAlterados.push(titulo);
      }
    }

    async function buscarTitulosReceber() {
      for (let i = 0; i < props.titulos.length; i += 1) {
        const titulo = await servicoTituloFinanceiroReceber.obter(props.titulos[i].codigo, props.operacao.empresaSelecionada);
        state.titulosAlterados.push(titulo);
      }
    }

    function limparTela() {
      tituloFinanceiroBase.titulosAlterar = [] as IDTOTituloFinanceiro[];
      state.titulosAlterados = [] as ITituloFinanceiro[];
      gradeBase.codigosSelecionados = [] as number[];
      state.alterouCompetencia = false;
      state.alterouVencimento = false;
      state.dataVencimento = UtilitarioData.obterDataAtual();
      state.dataCompetencia = UtilitarioData.obterDataAtual();
      state.valor = 0.0;
      state.codigoConta = 0;
      state.codigoPlanoContaCategoria = 0;
    }

    function obterObjetoAlteracao() {
      for (let i = 0; i < state.titulosAlterados.length; i += 1) {
        if (state.alterouVencimento) {
          state.titulosAlterados[i].dataVencimento = state.dataVencimento;
        }
        if (state.alterouCompetencia) {
          state.titulosAlterados[i].dataCompetencia = state.dataCompetencia;
        }
        if (state.codigoConta > 0) {
          state.titulosAlterados[i].codigoConta = state.codigoConta;
        }
        if (state.codigoPlanoContaCategoria > 0) {
          state.titulosAlterados[i].codigoPlanoContaCategoria = state.codigoPlanoContaCategoria;
        }
        if (state.valor > 0) {
          state.titulosAlterados[i].valorTotal = state.valor;
          state.titulosAlterados[i].saldo = state.valor;
        }
      }
    }

    async function AlterarTitulos() {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      apresentarBarraProgresso();
      obterObjetoAlteracao();
      if (props.tipo === ETipoTituloFinanceiro.Pagar) {
        retorno = await servicoTituloFinanceiroPagar.alterarEmMassa(state.titulosAlterados);
      } else {
        retorno = await servicoTituloFinanceiroReceber.alterarEmMassa(state.titulosAlterados);
      }
      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        emit('confirmacao');
        apresentarMensagemSucesso(retorno.mensagem);
        limparTela();
        modalBase.computedVisivel = false;
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    function ConfirmarAlteracao() {
      Modal.confirm({
        title: 'Todos os títulos serão alterados com os valores informados. Deseja continuar?',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        centered: true,
        onOk: async () => {
          await AlterarTitulos();
        },
      });
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      limparTela();
      if (modalBase.computedVisivel) {
        if (props.operacao.listaPermissoesDados.length > 0) {
          await preencherPermissoesDados(props.operacao.listaPermissoesDados);
        } else {
          await obterPermissoes(ETipoPermissao.Dados);
        }
        telaBase.permissaoDados = await filtrarPermissaoDadosUsuario(props.empresa);
        preencherColunas();
        if (props.tipo === ETipoTituloFinanceiro.Pagar) {
          buscarTitulos();
        } else {
          buscarTitulosReceber();
        }
      }
      telaBase.carregando = false;
    });

    return {
      telaBase,
      state,
      gradeBase,
      modalBase,
      tituloFinanceiroBase,
      props,
      UtilitarioMascara,
      UtilitarioData,
      UtilitarioGeral,
      window,
      obtemValorTitulo,
      ECustomRenderRow,
      ETipoArquivo,
      EStatusTituloFinanceiro,
      obterClasseStatus,
      ConfirmarAlteracao,
      storeSistema,
    };
  },
});
