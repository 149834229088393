
import {
  defineComponent, onBeforeMount, reactive, ref, watch,
} from 'vue';
import { Modal } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useTituloFinanceiroBase } from '@/composables/Financeiro/TituloFinanceiroBase';
import TituloPadrao from '@/core/components/Tela/TituloPadrao.vue';
import PreferenciasColuna from '@/core/components/Preferencias/ColunaTabela.vue';
import Icone from '@/core/components/Icone.vue';
import PreviewPdf from '@/core/components/Modal/PreviewPdf.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import BuscaGeral from '@/core/components/BuscaAvancada/BuscaGeral.vue';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IListaPaginadaMetaData } from '@/core/models/Consulta/IListaPaginada';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { IPagination } from '@/core/models/AntDesign/IPagination';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { EVinculoEmpresa } from '@/core/models/Enumeradores/EEVinculoEmpresa';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import storeSistema from '@/store/storeSistema';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { useGradeBase } from '@/core/composables/GradeBase';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import ContaPagarModal from './ContaPagarModal.vue';
import Card from '@/core/components/Tela/Card.vue';
import TituloFinanceiroTotalizadores from '@/components/Financeiro/TitulosFinanceiros/TituloFinanceiroTotalizadores.vue';
import { ETipoTituloFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoTituloFinanceiro';
import { EStatusTituloFinanceiro } from '@/models/Enumeradores/Financeiro/EStatusTituloFinanceiro';
import { IDTOTituloFinanceiro } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiro';
import CalendarioEventos from '@/core/components/Tela/CalendarioEventos.vue';
import { IFiltroGenericoAdicionado } from '@/core/models/BuscaAvancada/IFiltroGenericoAdicionado';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { EOperadorLogico } from '@/core/models/Enumeradores/EOperadorLogico';
import ServicoTituloFinanceiroPagar from '@/servicos/Financeiro/ServicoTituloFinanceiroPagar';
import router from '@/router';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import { IParametrosConsultaTituloFinanceiro } from '@/models/ParametrosRequisicao/Financeiro/IParametrosConsultaTituloFinanceiro';
import { IDTOUsuarioPermissoes } from '@/models/DTO/MeuSistema/Usuarios/IDTOUsuarioPermissoes';
import storeFinanceiro from '@/store/storeFinanceiro';
import { IArquivoPdf } from '@/core/models/IArquivoPdf';
import ImportacaoRegistrosExcelModal from '@/components/Financeiro/TitulosFinanceiros/ImportacaoRegistrosExcelModal.vue';
import ServicoParametros from '@/servicos/MeuSistema/ServicoParametros';
import AlteracaoFinanceiroMassaModal from '../AlteracaoFinanceiroMassa/AlteracaoFinanceiroMassaModal.vue';

export default defineComponent({
  name: 'ContaPagar',
  components: {
    Icone,
    Card,
    MensagemSemDados,
    BuscaGeral,
    PreferenciasColuna,
    TituloFinanceiroTotalizadores,
    ContaPagarModal,
    CalendarioEventos,
    TituloPadrao,
    ImportacaoRegistrosExcelModal,
    PreviewPdf,
    AlteracaoFinanceiroMassaModal,
  },
  setup(props) {
    const route = useRoute();
    const servicoTituloFinanceiroPagar = new ServicoTituloFinanceiroPagar();
    const servicoSistema = new ServicoSistema();
    const servicoParametro = new ServicoParametros();
    servicoParametro.requisicaoSistema();
    const refTotalizadores = ref<InstanceType<typeof TituloFinanceiroTotalizadores>>();
    const {
      telaBase, obterPermissoes, preencherEmpresasDisponiveis, verificaConceitoParaApresentarEmpresas,
      defineEmpresasSelecionadasComPermissao, filtrarPermissaoDadosUsuarioMultiEmpresas,
      apresentarMensagemSucesso, apresentarMensagemAlerta, apresentarMensagemErro,
      adicionarAtalho, removerAtalho, preencherDadosRota, salvarPreferenciasFiltros, salvarPreferenciasOrdenacao,
      carregarPreferenciasFiltros, carregarPreferenciasOrdenacao, carregarPreferenciasGrade, salvarPreferenciasGrade,
    } = useTelaBase();

    const { gradeBase, redefinirPersonalizacaoColunas } = useGradeBase();

    const {
      tituloFinanceiroBase, obterClasseStatus, popularDadosEventos, adicionaFiltroVencimentoHoje,
      adicionaFiltroVencimentoEsteMes, adicionaFiltroVencimentoAtrasados, adicionaFiltroBaixadosHoje,
      adicionaFiltroBaixadosEsteMes, adicionaFiltroBaixadosAtrasados, obtemValorTitulo,
    } = useTituloFinanceiroBase(props);

    const state = reactive({
      empresaSelecionada: 0,
      exibirPersonalizarColunas: false,
      paginacao: {} as IPagination,
      paginacaoApi: { totalRegistros: 0, paginaAtual: 1 } as IListaPaginadaMetaData,
      timerRegistros: 0,
      totalRegistrosComMascara: '100',
      exibirLancamento: false,
      operacaoLancamento: {} as ITelaOperacao,
      carregandoCalendario: false,
      exibirImportacaoExcel: false,
      autorizacoes: [] as IDTOUsuarioPermissoes[],
      arquivosPdf: [] as IArquivoPdf[],
      totalizadorSaldo: 0,
      totalSelecionado: 0,
      exibirAlteracao: false,
    });
    telaBase.apresentarEmpresas = false;
    function preencherColunas() {
      gradeBase.colunasMobile = [
        {
          title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
        },
        {
          title: 'Título', dataIndex: 'titulo', key: 'TituloFinanceiro', position: 2, visible: true,
        },
      ];

      gradeBase.colunasPadrao = [
        {
          title: 'Código', dataIndex: 'codigoTituloFinanceiro', key: 'Codigo', position: 1, visible: false,
        },
        {
          title: 'T.Documento', dataIndex: 'tipoDocumentoFinanceiro', key: 'TipoDocumentoFinanceiro', position: 2, visible: true, width: 28, ellipsis: true, align: 'center', fixed: 'left',
        },
        {
          title: 'N.Identificação', dataIndex: 'numeroTitulo', key: 'NumeroTitulo', position: 3, visible: true, ellipsis: true, customRenderRow: ECustomRenderRow.LinkAcao, width: 75, fixed: 'left',
        },
        {
          title: 'Valor R$', dataIndex: 'valor', key: 'Valor', position: 4, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, fixed: 'left', align: 'right', width: 80,
        },
        {
          title: 'Status', dataIndex: 'descricaoStatus', key: 'Status', position: 5, visible: true, ordering: true, ellipsis: true, width: 75, align: 'left',
        },
        {
          title: 'Pessoa', dataIndex: 'nomePessoa', key: 'NomePessoa', position: 6, visible: true, ellipsis: true, width: 150,
        },
        {
          title: 'Vencimento', dataIndex: 'dataVencimentoFormatada', key: 'DataVencimento', position: 7, visible: true, ordering: false, width: 70, align: 'center',
        },
        {
          title: 'Competência', dataIndex: 'dataCompetenciaFormatada', key: 'DataCompetencia', position: 8, visible: true, ordering: false, width: 70, align: 'center',
        },
        {
          title: 'Baixa', dataIndex: 'dataBaixaFormatada', key: 'DataBaixa', position: 9, visible: true, ordering: false, width: 70, align: 'center',
        },
        {
          title: 'Conta', dataIndex: 'descricaoConta', key: 'Conta', position: 10, visible: true, ellipsis: true, width: 150,
        },
        {
          title: 'Categoria', dataIndex: 'nomePlanoContaCategoria', key: 'NomePlanoContaCategoria', position: 11, visible: true, ellipsis: true, width: 150,
        },
        {
          title: 'Doc.Fiscal', dataIndex: 'numeroDocumentoFiscal', key: 'NumeroDocumentoFiscal', position: 12, visible: true, ordering: true, width: 65, align: 'right',
        },
        {
          title: 'Observações', dataIndex: 'observacoes', key: 'Observacoes', position: 13, visible: true, ellipsis: true, width: 150,
        },
      ];
    }

    async function preencherEventos() {
      const dataInicial = UtilitarioData.obterPrimeiroDiaMes(tituloFinanceiroBase.dataCalendario);
      const dataFinal = UtilitarioData.obterUltimoDiaMes(tituloFinanceiroBase.dataCalendario);
      tituloFinanceiroBase.titulosCalendario = await servicoTituloFinanceiroPagar.ObterTitulosCalendario(state.empresaSelecionada, dataInicial, dataFinal);
      popularDadosEventos();
    }

    async function buscarTitulos() {
      tituloFinanceiroBase.titulos = [];
      gradeBase.codigosSelecionados = [];
      gradeBase.filtrosAplicados = [];
      tituloFinanceiroBase.buscaAvancada.filtrosAdicionados.forEach((item) => {
        gradeBase.filtrosAplicados.push(item.filtro);
      });

      const parametrosConsulta = {} as IParametrosConsultaTituloFinanceiro;
      parametrosConsulta.empresas = [state.empresaSelecionada];
      parametrosConsulta.numeroPagina = state.paginacao.current;
      parametrosConsulta.qtdeRegistrosPagina = state.paginacao.pageSize;
      parametrosConsulta.qtdeRegistrosTotal = state.paginacao.total;
      parametrosConsulta.valorBuscaRapida = tituloFinanceiroBase.buscaRapida;
      parametrosConsulta.ordenacao = Array<string>();

      telaBase.ordenacaoSelecionada.forEach((ordenacao) => {
        parametrosConsulta.ordenacao.push(`${ordenacao.identificador}|${ordenacao.ordem}`);
      });

      if (!UtilitarioGeral.validaLista(gradeBase.ordenacaoSelecionada)) {
        parametrosConsulta.ordenacao.push('DataVencimento|ASC');
      }

      telaBase.carregando = true;
      const listaPaginada = await servicoTituloFinanceiroPagar.buscarTitulos(parametrosConsulta, gradeBase.filtrosAplicados);
      tituloFinanceiroBase.titulos = listaPaginada.dados;
      state.paginacaoApi = listaPaginada.metaData;
      telaBase.carregando = false;
      state.totalizadorSaldo = 0;
      tituloFinanceiroBase.titulos.forEach((t) => {
        state.totalizadorSaldo += t.valorTotal;
      });
    }

    async function atualizarTotalizadores() {
      if (refTotalizadores.value !== undefined) {
        await refTotalizadores.value.obterValores();
      }
    }

    function abrirImportacaoExcel() {
      state.exibirImportacaoExcel = true;
    }

    async function atualizarSelecaoEmpresas() {
      telaBase.empresasSelecionadas = [] as number[];
      telaBase.empresasSelecionadas.push(state.empresaSelecionada);
      telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
      state.autorizacoes = await servicoSistema.obterVariasPermissoesAutorizacoesUsuario(['AUT_BAIXAS_CONTAS_PAGAR']);
      atualizarTotalizadores();
      state.carregandoCalendario = true;
      await buscarTitulos();
      await preencherEventos();
      state.carregandoCalendario = false;
    }

    async function aplicarFiltros() {
      state.paginacao.current = 1;
      await buscarTitulos();
    }

    function clickDia(dia: string) {
      const filtroVencimento = tituloFinanceiroBase.buscaAvancada.filtrosAdicionados.findIndex((filtro) => filtro.filtro.identificador === 'DataVencimento');
      if (filtroVencimento > -1) {
        tituloFinanceiroBase.buscaAvancada.filtrosAdicionados.splice(filtroVencimento, 1);
      }

      const filtro = {} as IFiltroGenericoAdicionado;
      tituloFinanceiroBase.buscaAvancada.chaveFiltrosAdicionados += 1;
      filtro.codigo = tituloFinanceiroBase.buscaAvancada.chaveFiltrosAdicionados;
      filtro.filtro = {} as IFiltroGenerico;
      filtro.filtro.identificador = 'DataVencimento';
      filtro.apresentacao = 'Data do Vencimento';
      filtro.filtro.tipoDado = 4;
      filtro.filtro.operador = EOperadorLogico.E;
      filtro.filtro.apresentacao = 'Data do Vencimento';
      filtro.filtro.condicao = 4;
      filtro.filtro.valores = [dia];
      filtro.detalhes = `igual a: ${UtilitarioData.aplicaFormatoData(dia)}`;
      tituloFinanceiroBase.buscaAvancada.filtrosAdicionados.push(filtro);
      aplicarFiltros();
    }

    function filtrarTitulosPelosTotalizadores(conceito: string) {
      tituloFinanceiroBase.buscaAvancada.filtrosAdicionados = [];
      gradeBase.codigosSelecionados = [];
      tituloFinanceiroBase.titulosBaixar = [];
      tituloFinanceiroBase.titulosExcluir = [];
      tituloFinanceiroBase.titulosEstornar = [];
      switch (conceito) {
        case 'VencimentoHoje':
          adicionaFiltroVencimentoHoje();
          break;
        case 'VencimentoMes':
          adicionaFiltroVencimentoEsteMes();
          break;
        case 'Atrasados':
          adicionaFiltroVencimentoAtrasados();
          break;
        case 'BaixadosHoje':
          adicionaFiltroBaixadosHoje();
          break;
        case 'BaixadosMes':
          adicionaFiltroBaixadosEsteMes();
          break;
        case 'BaixadosAtrasdos':
          adicionaFiltroBaixadosAtrasados();
          break;
        default:
      }
      aplicarFiltros();
    }

    async function alterarQuantidadeRegistroTotal(valor: any) {
      clearTimeout(state.timerRegistros);
      let totalRegistros = 100;
      state.timerRegistros = setTimeout(async () => {
        if (UtilitarioGeral.valorValido(valor)) {
          const limiteRegistros = UtilitarioMascara.removerMascaraNumero(valor);
          if (limiteRegistros > 0) {
            totalRegistros = limiteRegistros;
          }
        }
        state.paginacao.total = totalRegistros;
        state.totalRegistrosComMascara = UtilitarioMascara.mascararNumeroInteiro(totalRegistros);
        await buscarTitulos();
      }, 600);
    }

    async function mudarPagina(current: number, pageSize: number) {
      state.paginacao.current = current;
      state.paginacao.pageSize = pageSize;
      await buscarTitulos();
    }

    async function mostrarNovaPagina(current: number, pageSize: number) {
      state.paginacao.current = 1;
      state.paginacao.pageSize = pageSize;
      await buscarTitulos();
    }

    function baixarTitulos() {
      const token = UtilitarioGeral.gerarTokenUnico();
      storeFinanceiro.mutations.adicionarTitulosComFiltros(token, 'ContasPagar', 'Baixar titulos contas a Pagar', tituloFinanceiroBase.titulosBaixar, tituloFinanceiroBase.buscaRapida, tituloFinanceiroBase.buscaAvancada);
      router.push({ name: 'BaixaContasPagar', query: { token } });
    }

    function alterarTitulos() {
      state.operacaoLancamento = {} as ITelaOperacao;
      state.operacaoLancamento.tipoPermissaoDados = EPermissaoDados.Alterar;
      state.operacaoLancamento.listaPermissoesDados = telaBase.listaPermissoesDados;
      state.operacaoLancamento.empresaSelecionada = state.empresaSelecionada;
      state.exibirAlteracao = true;
    }

    function verificaAcoesTitulosSelecionados() {
      tituloFinanceiroBase.titulosBaixar = [];
      tituloFinanceiroBase.titulosExcluir = [];
      tituloFinanceiroBase.titulosEstornar = [];
      tituloFinanceiroBase.titulosAlterar = [];
      state.totalSelecionado = 0;
      if (UtilitarioGeral.validaLista(gradeBase.codigosSelecionados)) {
        const titulosSelecionados = tituloFinanceiroBase.titulos.filter((c) => gradeBase.codigosSelecionados.includes(c.codigo));

        const titulosPendentes = titulosSelecionados.filter((c) => c.status === EStatusTituloFinanceiro.Pendente || c.status === EStatusTituloFinanceiro.BaixadoParcialmente || c.status === EStatusTituloFinanceiro.AprovacaoPendente || c.status === EStatusTituloFinanceiro.Reprovado);
        if (UtilitarioGeral.validaLista(titulosPendentes)) {
          tituloFinanceiroBase.titulosBaixar = titulosPendentes.filter((c) => c.status !== EStatusTituloFinanceiro.AprovacaoPendente && c.status !== EStatusTituloFinanceiro.Reprovado);
          tituloFinanceiroBase.titulosExcluir = titulosPendentes;
        }

        const titulosAlteracao = titulosSelecionados;
        tituloFinanceiroBase.titulosAlterar = titulosAlteracao;

        const titulosEstornar = titulosSelecionados.filter((c) => c.status === EStatusTituloFinanceiro.Baixado || c.status === EStatusTituloFinanceiro.BaixadoCartao || c.status === EStatusTituloFinanceiro.BaixadoCredito);
        if (UtilitarioGeral.validaLista(titulosEstornar)) {
          tituloFinanceiroBase.titulosEstornar = titulosEstornar;
        }
        titulosSelecionados.forEach((t) => {
          state.totalSelecionado += t.valorTotal;
        });
      }
    }
    const mudarSelecao = (selectedRowKeys: any) => {
      gradeBase.codigosSelecionados = selectedRowKeys;
      verificaAcoesTitulosSelecionados();
    };

    function incluir() {
      state.operacaoLancamento = {} as ITelaOperacao;
      state.operacaoLancamento.tipoPermissaoDados = EPermissaoDados.Incluir;
      state.operacaoLancamento.listaPermissoesDados = telaBase.listaPermissoesDados;
      state.operacaoLancamento.codigoRegistro = 0;
      state.operacaoLancamento.empresaSelecionada = state.empresaSelecionada;
      state.exibirLancamento = true;
    }

    function editar(codigoRegistro: number) {
      state.operacaoLancamento = {} as ITelaOperacao;
      state.operacaoLancamento.tipoPermissaoDados = EPermissaoDados.Alterar;
      state.operacaoLancamento.listaPermissoesDados = telaBase.listaPermissoesDados;
      state.operacaoLancamento.codigoRegistro = codigoRegistro;
      state.operacaoLancamento.empresaSelecionada = state.empresaSelecionada;
      state.exibirLancamento = true;
    }

    async function imprimir(tipoArquivoRelatorio:ETipoArquivo, codigoRegistro?:number) {
      gradeBase.filtrosAplicados = [];

      tituloFinanceiroBase.buscaAvancada.filtrosAdicionados.forEach((item) => {
        gradeBase.filtrosAplicados.push(item.filtro);
      });
      const parametrosConsulta = {} as IParametrosConsultaTituloFinanceiro;
      parametrosConsulta.empresas = [state.empresaSelecionada];
      parametrosConsulta.numeroPagina = 1;
      parametrosConsulta.qtdeRegistrosPagina = 0;
      parametrosConsulta.qtdeRegistrosTotal = 0;
      parametrosConsulta.ordenacao = Array<string>();

      gradeBase.ordenacaoSelecionada.forEach((item) => {
        parametrosConsulta.ordenacao.push(`${item.identificador}|${item.ordem}`);
      });
      let codigosSelecionados: number[] = [];
      if (codigoRegistro !== undefined && codigoRegistro > 0) {
        codigosSelecionados.push(codigoRegistro);
      } else {
        codigosSelecionados = gradeBase.codigosSelecionados;
      }

      telaBase.carregando = true;
      const retornoRelatorio = await servicoTituloFinanceiroPagar.relatorioPadrao(tipoArquivoRelatorio, parametrosConsulta, gradeBase.filtrosAplicados, codigosSelecionados);
      telaBase.carregando = false;
      if (retornoRelatorio.status === EStatusRetornoRequisicao.Sucesso) {
        if (tipoArquivoRelatorio === ETipoArquivo.PDF) {
          state.arquivosPdf = [{ nome: '', link: retornoRelatorio.link } as IArquivoPdf];
        } else {
          UtilitarioGeral.downloadArquivo(retornoRelatorio.link);
        }
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retornoRelatorio.mensagem);
      }
    }

    async function carregarPreferencias() {
      telaBase.preferencias = await new ServicoSistema().obterPreferenciaRecurso(telaBase.identificadorRecurso, telaBase.empresasSelecionadas);
      if (telaBase.preferencias !== null) {
        tituloFinanceiroBase.buscaAvancada.filtrosAdicionados = carregarPreferenciasFiltros(telaBase.preferencias);
        telaBase.ordenacaoSelecionada = carregarPreferenciasOrdenacao(telaBase.preferencias);
        gradeBase.colunas = carregarPreferenciasGrade(gradeBase.colunasPadrao, telaBase.preferencias);
      }
    }

    async function imprimirRemessa(primeiroTitulo: number) {
      const retornoRelatorio = await servicoTituloFinanceiroPagar.gerarRelatorioRemessa(state.empresaSelecionada, primeiroTitulo);
      if (retornoRelatorio.status === EStatusRetornoRequisicao.Sucesso) {
        state.arquivosPdf = [{ nome: '', link: retornoRelatorio.link } as IArquivoPdf];
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retornoRelatorio.mensagem);
      }
    }

    async function gerarRemessa(conta: number, titulos: number[]) {
      const retorno = await servicoTituloFinanceiroPagar.gerarRemessa(state.empresaSelecionada, conta, titulos);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        UtilitarioGeral.downloadArquivo(retorno.mensagem);
        await imprimirRemessa(titulos[0]);
      } else {
        apresentarMensagemErro(retorno.mensagem);
      }
    }

    async function gerarRemessaContas() {
      const contas = [] as number[];
      const titulosSelecionados = tituloFinanceiroBase.titulos.filter((c) => gradeBase.codigosSelecionados.includes(c.codigo));

      titulosSelecionados.forEach((t) => {
        if (t.status === EStatusTituloFinanceiro.Pendente || t.status === EStatusTituloFinanceiro.BaixadoParcialmente) {
          const index = contas.findIndex((c) => c === t.codigoConta);
          if (index === -1) {
            contas.push(t.codigoConta);
          }
        }
      });

      if (contas.length === 1) {
        const titulosConta = titulosSelecionados.filter((t) => t.codigoConta === contas[0] && (t.status === EStatusTituloFinanceiro.Pendente || t.status === EStatusTituloFinanceiro.BaixadoParcialmente));
        const titulos = [] as number[];

        titulosConta.forEach((t) => {
          titulos.push(t.codigo);
        });
        await gerarRemessa(contas[0], titulos);
      } else {
        contas.forEach((c) => {
          const titulosConta = titulosSelecionados.filter((t) => t.codigoConta === c && (t.status === EStatusTituloFinanceiro.Pendente || t.status === EStatusTituloFinanceiro.BaixadoParcialmente));
          const titulos = [] as number[];

          titulosConta.forEach((t) => {
            titulos.push(t.codigo);
          });
          gerarRemessa(c, titulos);
        });
      }
      gradeBase.codigosSelecionados = [] as number[];
      buscarTitulos();
    }

    async function confirmarGerarRemessa() {
      if (tituloFinanceiroBase.titulosBaixar.length === 0) {
        apresentarMensagemAlerta('Nenhum título pendente selecionado!');
        return;
      }
      Modal.confirm({
        title: tituloFinanceiroBase.titulosBaixar.length > 1 ? 'Realmente deseja gerar remessa para os títulos selecionados?' : 'Realmente deseja gerar remessa para o título selecionado?',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        centered: true,
        onOk: async () => {
          await gerarRemessaContas();
        },
      });
    }

    onBeforeMount(async () => {
      state.empresaSelecionada = storeSistema.getters.codigoEmpresaOperacao();
      gradeBase.layoutMobile = storeSistema.state.layoutMobile;
      preencherDadosRota();
      await obterPermissoes(ETipoPermissao.Dados);
      await preencherEmpresasDisponiveis();
      await defineEmpresasSelecionadasComPermissao();
      const parametroValor = await servicoParametro.obterValor(storeSistema.getters.codigoEmpresaOperacao(), 'PAR_FIN_ALCADA_DESPESAS');
      tituloFinanceiroBase.controlarAlcada = parametroValor.valor === 'true';
      tituloFinanceiroBase.dataAtual = await servicoSistema.obterDataAtual();
      tituloFinanceiroBase.dataCalendario = tituloFinanceiroBase.dataAtual;
      state.autorizacoes = await servicoSistema.obterVariasPermissoesAutorizacoesUsuario(['AUT_BAIXAS_CONTAS_PAGAR']);
      telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas([state.empresaSelecionada]);
      verificaConceitoParaApresentarEmpresas();
      telaBase.propriedadesConsulta = await servicoTituloFinanceiroPagar.obterPropriedadesConsulta(state.empresaSelecionada);
      if (telaBase.propriedadesConsulta.length > 0) {
        tituloFinanceiroBase.buscaAvancada.filtros = telaBase.propriedadesConsulta.filter((item) => item.filtro === true);
        tituloFinanceiroBase.buscaAvancada.ordenacao = telaBase.propriedadesConsulta.filter((item) => item.ordenacao === true);
      }
      tituloFinanceiroBase.buscaAvancada.filtrosAdicionados = [];
      tituloFinanceiroBase.buscaAvancada.chaveFiltrosAdicionados = 0;
      preencherColunas();
      await carregarPreferencias();

      if (route.query.token !== undefined) {
        const tokenStore = String(route.query.token);
        if (UtilitarioGeral.valorValido(tokenStore)) {
          const tituloFinanceiroStore = storeFinanceiro.getters.obterTituloFinanceiroStore(tokenStore);
          if (UtilitarioGeral.objetoValido(tituloFinanceiroStore)) {
            tituloFinanceiroBase.buscaRapida = tituloFinanceiroStore.buscaRapida;
            if (UtilitarioGeral.objetoValido(tituloFinanceiroStore.buscaAvancada)) {
              tituloFinanceiroBase.buscaAvancada = tituloFinanceiroStore.buscaAvancada;
            }
          }
        }
      }
      state.paginacao.total = 100;
      state.paginacao.showSizeChanger = true;
      state.paginacao.pageSize = 100;
      state.paginacao.pageSizeOptions = ['10', '50', '100', '500', '1000'];
      state.carregandoCalendario = true;
      if (UtilitarioGeral.validaLista(tituloFinanceiroBase.buscaAvancada.filtrosAdicionados)) {
        aplicarFiltros();
      } else {
        filtrarTitulosPelosTotalizadores('VencimentoMes');
      }
      await preencherEventos();
      state.carregandoCalendario = false;
    });

    async function atualizarInformacoesTela() {
      await atualizarTotalizadores();
      await preencherEventos();
      await buscarTitulos();
      verificaAcoesTitulosSelecionados();
    }

    async function excluir(codigo: number) {
      const retorno = await servicoTituloFinanceiroPagar.excluir(codigo, state.empresaSelecionada);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        await atualizarInformacoesTela();
      } else if (retorno.status === EStatusRetornoRequisicao.Alerta) {
        Modal.warning({
          title: 'Não foi possível concluir a exclusão!',
          content: retorno.mensagem,
        });
      }
    }

    async function confirmarExclusao(objeto: IDTOTituloFinanceiro) {
      Modal.confirm({
        title: 'Você confirma a exclusão da conta a pagar',
        content: `${objeto.numeroTitulo} ?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        centered: true,
        onOk: async () => { await excluir(objeto.codigo); },
      });
    }

    async function excluirEmMassa() {
      const retorno = await servicoTituloFinanceiroPagar.excluirEmMassa(state.empresaSelecionada, tituloFinanceiroBase.titulosExcluir.map((c) => c.codigo));
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        tituloFinanceiroBase.titulosExcluir = [];
        await atualizarInformacoesTela();
      } else if (retorno.status === EStatusRetornoRequisicao.Alerta) {
        Modal.warning({
          title: 'Não foi possível concluir a exclusão!',
          content: retorno.mensagem,
        });
      }
    }

    async function confirmarExclusaoEmMassa() {
      const numeroTitulos = tituloFinanceiroBase.titulosExcluir.map((t) => t.numeroTitulo);
      Modal.confirm({
        title: tituloFinanceiroBase.titulosExcluir.length > 1 ? 'Você confirma a exclusão dos títulos selecionados:' : 'Você confirma a exclusão do título selecionado:',
        content: `${numeroTitulos.join(', ')} ?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        centered: true,
        onOk: async () => {
          await excluirEmMassa();
        },
      });
    }

    function verificarAutorizacao(identificadorAutorizacao: string) {
      return servicoSistema.verificaAutorizacao(state.empresaSelecionada, identificadorAutorizacao, state.autorizacoes);
    }

    function textoCodigosSelecionados():string {
      if (gradeBase.codigosSelecionados.length === 1) { return '1 - Registro selecionado'; }

      if (gradeBase.codigosSelecionados.length > 1) { return `${gradeBase.codigosSelecionados.length} - Registros selecionados`; }

      return '';
    }
    watch(() => storeSistema.state.layoutMobile, () => {
      gradeBase.layoutMobile = storeSistema.state.layoutMobile;
      preencherColunas();
    });

    function apresentarPersonalizarColuna() {
      state.exibirPersonalizarColunas = !state.exibirPersonalizarColunas;
    }
    return {
      tituloFinanceiroBase,
      state,
      props,
      window,
      ETipoTituloFinanceiro,
      EStatusTituloFinanceiro,
      EVinculoEmpresa,
      ETipoArquivo,
      ECustomRenderRow,
      UtilitarioMascara,
      UtilitarioData,
      UtilitarioGeral,
      storeSistema,
      telaBase,
      gradeBase,
      incluir,
      editar,
      confirmarExclusao,
      confirmarExclusaoEmMassa,
      aplicarFiltros,
      mudarPagina,
      mostrarNovaPagina,
      alterarQuantidadeRegistroTotal,
      buscarTitulos,
      imprimir,
      obterClasseStatus,
      mudarSelecao,
      atualizarSelecaoEmpresas,
      adicionarAtalho,
      removerAtalho,
      clickDia,
      preencherEventos,
      refTotalizadores,
      baixarTitulos,
      obtemValorTitulo,
      atualizarInformacoesTela,
      filtrarTitulosPelosTotalizadores,
      verificarAutorizacao,
      textoCodigosSelecionados,
      salvarPreferenciasFiltros,
      salvarPreferenciasOrdenacao,
      abrirImportacaoExcel,
      confirmarGerarRemessa,
      salvarPreferenciasGrade,
      redefinirPersonalizacaoColunas,
      apresentarPersonalizarColuna,
      alterarTitulos,
    };
  },
});
