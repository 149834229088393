
import { defineComponent, reactive } from 'vue';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IResultadoDREItem } from '@/models/Relatorios/Financeiro/DRE/IResultadoDRE';

export default defineComponent({
  name: 'ResultadoDRE',
  props: {
    resultado: {
      type: Object as () => IResultadoDREItem,
      required: true,
    },
  },
  components: {
  },
  setup(props) {
    const state = reactive({
    });

    return {
      props,
      state,
      UtilitarioGeral,
      UtilitarioMascara,
    };
  },
});
