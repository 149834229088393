
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { useTituloFinanceiroBase } from '@/composables/Financeiro/TituloFinanceiroBase';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import Card from '@/core/components/Tela/Card.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import SelecionarTipoDocumentoFinanceiro from '@/components/Cadastros/Financeiro/SelecionarTipoDocumentoFinanceiro.vue';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarPessoa from '@/components/Cadastros/Pessoas/SelecionarPessoa.vue';
import SelecionarConta from '@/components/Cadastros/Financeiro/SelecionarConta.vue';
import SelecionarCategoriaPlanoConta from '@/components/Cadastros/PlanosContas/SelecionarCategoriaPlanoConta.vue';
import TituloFinanceiroDocumentoFiscal from '@/components/Financeiro/TitulosFinanceiros/TituloFinanceiroDocumentoFiscal.vue';
import TituloFinanceiroCentroCusto from '@/components/Financeiro/TitulosFinanceiros/TituloFinanceiroCentroCusto.vue';
import TituloFinanceiroDetalhamento from '@/components/Financeiro/TitulosFinanceiros/TituloFinanceiroDetalhamento.vue';
import { IDTOTituloFinanceiroCentroCusto } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroCentroCusto';
import TituloFinanceiroAnexo from '@/components/Financeiro/TitulosFinanceiros/TituloFinanceiroAnexo.vue';
import TituloFinanceiroHistorico from '@/components/Financeiro/TitulosFinanceiros/TituloFinanceiroHistorico.vue';
import EditorHtml from '@/core/components/Tela/EditorHtml.vue';
import SelecionarSazonalidade from '@/core/components/Tela/SelecionarSazonalidade.vue';
import { ESazonalidade } from '@/core/models/Enumeradores/ESazonalidade';
import SelecionarMarcadorTituloFinanceiro from '@/components/Financeiro/TitulosFinanceiros/SelecionarMarcadorTituloFinanceiro.vue';
import { ITipoDocumentoFinanceiro } from '@/models/Entidades/Cadastros/Financeiro/ITipoDocumentoFinanceiro';
import ServicoTipoDocumentoFinanceiro from '@/servicos/Cadastros/Financeiro/ServicoTipoDocumentoFinanceiro';
import { EMeioPagamento } from '@/models/Enumeradores/Cadastros/Financeiro/EMeioPagamento';
import { ETipoTituloFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoTituloFinanceiro';
import TituloFinanceiroCheque from '@/components/Financeiro/TitulosFinanceiros/TituloFinanceiroCheque.vue';
import ServicoConta from '@/servicos/Cadastros/Financeiro/ServicoConta';
import TituloFinanceiroRepasse from '@/components/Financeiro/TitulosFinanceiros/TituloFinanceiroRepasse.vue';
import ServicoTituloFinanceiroReceber from '@/servicos/Financeiro/ServicoTituloFinanceiroReceber';
import ServicoPlanoContas from '@/servicos/Cadastros/PlanosContas/ServicoPlanoContas';
import { ETipoEmissaoBoleto } from '@/models/Enumeradores/Financeiro/ETipoEmissaoBoleto';
import TituloFinanceiroBoleto from '@/components/Financeiro/TitulosFinanceiros/Boletos/TituloFinanceiroBoleto.vue';
import { EStatusTituloFinanceiro } from '@/models/Enumeradores/Financeiro/EStatusTituloFinanceiro';
import { ITituloFinanceiro } from '@/models/Entidades/Financeiro/TitulosFinanceiros/ITituloFinanceiro';
import storeSistema from '@/store/storeSistema';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';

export default defineComponent({
  name: 'ContaReceberModal',
  props: {
    visivel: {
      type: Boolean,
    },
    editavel: {
      type: Boolean,
      default: true,
    },
    contaEditavel: {
      type: Boolean,
      default: true,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
    tituloFinanceiro: {
      type: Object as () => ITituloFinanceiro,
      default: {} as ITituloFinanceiro,
    },
  },
  components: {
    RequisicaoModal,
    Card,
    Icone,
    SelecionarTipoDocumentoFinanceiro,
    SelecionarData,
    CampoNumerico,
    SelecionarPessoa,
    SelecionarConta,
    SelecionarCategoriaPlanoConta,
    TituloFinanceiroDocumentoFiscal,
    TituloFinanceiroCentroCusto,
    TituloFinanceiroDetalhamento,
    TituloFinanceiroHistorico,
    TituloFinanceiroAnexo,
    EditorHtml,
    SelecionarSazonalidade,
    SelecionarMarcadorTituloFinanceiro,
    TituloFinanceiroCheque,
    TituloFinanceiroRepasse,
    TituloFinanceiroBoleto,
  },
  emits: ['update:operacao', 'update:visivel', 'confirmacao'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissaoDados, verificaConceitoParaApresentarEmpresas,
      preencherPermissoesDados, filtrarPermissaoDadosUsuario, apresentarMensagemSucesso, apresentarMensagemAlerta,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);
    const servicoTituloFinanceiroReceber = new ServicoTituloFinanceiroReceber();
    const servicoTipoDocumentoFinanceiro = new ServicoTipoDocumentoFinanceiro();
    servicoTipoDocumentoFinanceiro.requisicaoSistema();
    const servicoPlanoContas = new ServicoPlanoContas();
    servicoPlanoContas.requisicaoSistema();
    const servicoConta = new ServicoConta();
    servicoConta.requisicaoSistema();
    const servicoSistema = new ServicoSistema();
    telaBase.identificadorRecurso = 'CONTAS_RECEBER';
    telaBase.identificadorPermissao = 'PER_CONTAS_RECEBER';
    const {
      tituloFinanceiroBase, limparBoleto, limparCheque, limparTela, atualizarStatus, atualizarSaldo, tituloPendente, obterSazonalidade, obterDescricaoSazonalidade, atualizarUltimoVencimento, obterMensagemRepeticao, obterCentrosCusto, obterMarcadores, checkRepetir, obterTotalDetalhado, verificarDetalhesPagamento, atualizarSaldoPeloDetalhamento,
    } = useTituloFinanceiroBase(props);

    const state = reactive({
      exibirRemessa: false,
      editavelBaixa: true,
      apresentaCentroCusto: true,
    });

    function atualizarEmpresaSelecionada() {
      telaBase.empresasSelecionadas = [];
      telaBase.empresasSelecionadas.push(tituloFinanceiroBase.titulo.codigoEmpresa);
    }

    async function atualizarTipoDocumentoFinanceiroSelecionado() {
      if (UtilitarioGeral.valorValido(tituloFinanceiroBase.titulo.codigoTipoDocumentoFinanceiro)) {
        tituloFinanceiroBase.tipoDocumentoFinanceiro = await servicoTipoDocumentoFinanceiro.obter(tituloFinanceiroBase.titulo.codigoTipoDocumentoFinanceiro, tituloFinanceiroBase.titulo.codigoEmpresa);
        if (tituloFinanceiroBase.tipoDocumentoFinanceiro.meioPagamento === EMeioPagamento.BoletoBancario && (tituloFinanceiroBase.titulo.boleto.numeroDocumento === undefined || tituloFinanceiroBase.titulo.boleto.numeroDocumento === '')) {
          tituloFinanceiroBase.titulo.boleto.numeroDocumento = tituloFinanceiroBase.titulo.numeroTitulo;
        }
      } else {
        tituloFinanceiroBase.tipoDocumentoFinanceiro = {} as ITipoDocumentoFinanceiro;
      }
    }

    async function obterCentrosCustoContaContabil() {
      tituloFinanceiroBase.carregandoCentrosCusto = true;
      if (tituloFinanceiroBase.titulo.codigoPlanoContaCategoria > 0 && !UtilitarioGeral.validaLista(tituloFinanceiroBase.centrosCusto)) {
        const centrosCusto = await servicoPlanoContas.obterCentrosCustos(tituloFinanceiroBase.titulo.codigoPlanoContaCategoria, tituloFinanceiroBase.titulo.codigoEmpresa);
        centrosCusto.forEach((c) => {
          const centroCusto = {} as IDTOTituloFinanceiroCentroCusto;
          centroCusto.codigoCentroCusto = c.codigoCentroCusto;
          centroCusto.descricaoCentroCusto = c.descricaoCentroCusto;
          centroCusto.proporcao = c.proporcao;
          centroCusto.ordem = tituloFinanceiroBase.centrosCusto.length + 1;
          tituloFinanceiroBase.centrosCusto.push(centroCusto);
        });
      }
      tituloFinanceiroBase.carregandoCentrosCusto = false;
    }

    async function preencherDocumentoFiscal() {
      tituloFinanceiroBase.carregandoDocumentoFiscal = true;
      const documentoFiscal = await servicoTituloFinanceiroReceber.obterDocumentoFiscal(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
      if (UtilitarioGeral.objetoValido(documentoFiscal)) {
        if (UtilitarioGeral.validaCodigo(documentoFiscal.codigo)) {
          tituloFinanceiroBase.titulo.documentoFiscal = documentoFiscal;

          if (Number(tituloFinanceiroBase.titulo.documentoFiscal.tipoDocumento) === 0) {
            tituloFinanceiroBase.titulo.documentoFiscal.tipoDocumento = 1;
          }
          if (tituloFinanceiroBase.titulo.documentoFiscal.origem === 0) {
            tituloFinanceiroBase.titulo.documentoFiscal.origem = 1;
          }
          if (tituloFinanceiroBase.titulo.documentoFiscal.numeroDocumentoFiscal === 0) {
            tituloFinanceiroBase.titulo.documentoFiscal.numeroDocumentoFiscal = undefined;
          }
          if (tituloFinanceiroBase.titulo.documentoFiscal.documentoFiscal === 0) {
            tituloFinanceiroBase.titulo.documentoFiscal.documentoFiscal = undefined;
          }
        }
      }
      tituloFinanceiroBase.carregandoDocumentoFiscal = false;
    }

    async function preencherMarcadores() {
      tituloFinanceiroBase.titulo.marcadores = await servicoTituloFinanceiroReceber.obterMarcadores(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
      tituloFinanceiroBase.titulo.marcadores.forEach((m) => {
        tituloFinanceiroBase.marcadores.push(m.marcador);
      });
    }

    async function preencherBoleto() {
      tituloFinanceiroBase.carregandoBoleto = true;
      if (tituloFinanceiroBase.tipoDocumentoFinanceiro.meioPagamento === EMeioPagamento.BoletoBancario) {
        const boleto = await servicoTituloFinanceiroReceber.obterBoleto(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
        if (UtilitarioGeral.objetoValido(boleto)) {
          if (UtilitarioGeral.validaCodigo(boleto.codigo)) {
            tituloFinanceiroBase.titulo.boleto = boleto;
          }
        }
      } else {
        limparBoleto();
      }
      tituloFinanceiroBase.carregandoBoleto = false;
    }

    async function preencherCheque() {
      tituloFinanceiroBase.carregandoCheque = true;
      if (tituloFinanceiroBase.tipoDocumentoFinanceiro.meioPagamento === EMeioPagamento.Cheque) {
        const cheque = await servicoTituloFinanceiroReceber.obterCheque(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
        if (UtilitarioGeral.objetoValido(cheque)) {
          if (UtilitarioGeral.validaCodigo(cheque.codigo)) {
            tituloFinanceiroBase.titulo.cheque = cheque;
          }
        }
      } else {
        limparCheque();
      }
      tituloFinanceiroBase.carregandoCheque = false;
    }

    async function preencherDetalhamentos() {
      tituloFinanceiroBase.carregandoDetalhamentos = true;
      tituloFinanceiroBase.titulo.detalhamentos = await servicoTituloFinanceiroReceber.obterDetalhamentos(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
      obterTotalDetalhado();
      if (tituloFinanceiroBase.titulo.detalhamentos.some((d) => d.centroCusto?.codigo > 0)) {
        state.apresentaCentroCusto = false;
      } else {
        state.apresentaCentroCusto = true;
      }
      tituloFinanceiroBase.carregandoDetalhamentos = false;
    }

    async function preencherAnexos() {
      tituloFinanceiroBase.carregandoAnexos = true;
      tituloFinanceiroBase.titulo.anexos = await servicoTituloFinanceiroReceber.obterAnexos(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
      tituloFinanceiroBase.carregandoAnexos = false;
    }

    async function preencherCentrosCusto() {
      tituloFinanceiroBase.carregandoCentrosCusto = true;
      tituloFinanceiroBase.centrosCusto = await servicoTituloFinanceiroReceber.obterCentrosCusto(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
      tituloFinanceiroBase.carregandoCentrosCusto = false;
    }

    async function preencherHistorico() {
      tituloFinanceiroBase.carregandoHistorico = true;
      tituloFinanceiroBase.registrosAtividade = await servicoTituloFinanceiroReceber.obterRegistrosAtividade(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
      tituloFinanceiroBase.carregandoHistorico = false;
    }

    async function preencherRepasse() {
      tituloFinanceiroBase.carregandoRepasse = true;
      const repasse = await servicoTituloFinanceiroReceber.obterRepasse(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
      if (repasse !== null && repasse !== undefined && repasse.codigo > 0) {
        tituloFinanceiroBase.status = 'Repassado';
        tituloFinanceiroBase.titulo.repasse = repasse;
        tituloFinanceiroBase.tituloRepassado = true;
      }
      tituloFinanceiroBase.carregandoRepasse = false;
    }

    async function preencherTitulo() {
      tituloFinanceiroBase.titulo = await servicoTituloFinanceiroReceber.obter(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
      atualizarEmpresaSelecionada();
      await atualizarTipoDocumentoFinanceiroSelecionado();
      atualizarStatus();
      preencherDocumentoFiscal();
      preencherMarcadores();
      preencherBoleto();
      preencherCheque();
      preencherDetalhamentos();
      preencherAnexos();
      preencherCentrosCusto();
      preencherHistorico();
      preencherRepasse();
    }

    async function salvar(salvarNovo: boolean) {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      const retornoValidacao = verificarDetalhesPagamento();
      if (!retornoValidacao) {
        apresentarMensagemAlerta('O total dos detalhamentos não bate com o saldo do título!');
        return;
      }

      apresentarBarraProgresso();
      tituloFinanceiroBase.titulo.centrosCusto = obterCentrosCusto();
      tituloFinanceiroBase.titulo.marcadores = obterMarcadores();

      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        retorno = await servicoTituloFinanceiroReceber.incluir(tituloFinanceiroBase.titulo, tituloFinanceiroBase.repeticao, obterSazonalidade());
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Alterar) {
        retorno = await servicoTituloFinanceiroReceber.alterar(tituloFinanceiroBase.titulo);
      }
      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        emit('confirmacao', retorno.codigoRegistro);
        apresentarMensagemSucesso(retorno.mensagem);
        if (salvarNovo) {
          limparTela(ETipoTituloFinanceiro.Receber);
          const telaOperacao: ITelaOperacao = props.operacao;
          telaOperacao.codigoRegistro = 0;
          telaOperacao.tipoPermissaoDados = EPermissaoDados.Incluir;
          modalBase.computedOperacao = telaOperacao;
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    function atualizarNumeroBoleto() {
      if (tituloFinanceiroBase.editavel && tituloFinanceiroBase.tipoDocumentoFinanceiro.meioPagamento === EMeioPagamento.BoletoBancario && tituloFinanceiroBase.titulo.boleto.tipoEmissaoBoleto === ETipoEmissaoBoleto.Terceiros) {
        tituloFinanceiroBase.titulo.boleto.numeroDocumento = tituloFinanceiroBase.titulo.numeroTitulo;
      }
    }

    function atualizarParcela() {
      if (UtilitarioGeral.validaNumerico(tituloFinanceiroBase.titulo.parcela)) {
        tituloFinanceiroBase.titulo.parcela = 0;
      }
    }

    function abrirRemessa() {
      state.exibirRemessa = true;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      limparTela(ETipoTituloFinanceiro.Receber);
      state.exibirRemessa = false;
      if (modalBase.computedVisivel) {
        if (props.operacao.listaPermissoesDados.length > 0) {
          await preencherPermissoesDados(props.operacao.listaPermissoesDados);
        } else {
          await obterPermissoes(ETipoPermissao.Dados);
        }
        await preencherEmpresasComEstrategiaPermissaoDados(props.operacao.tipoPermissaoDados, false);
        verificaConceitoParaApresentarEmpresas();
        telaBase.permissaoDados = await filtrarPermissaoDadosUsuario(tituloFinanceiroBase.titulo.codigoEmpresa);
        tituloFinanceiroBase.titulo.codigoConta = await servicoConta.obterCodigoContaPadrao(tituloFinanceiroBase.titulo.codigoEmpresa);
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
          modalBase.textoBotaoSalvar = 'Concluir';
          modalBase.textoBotaoSalvarNovo = 'Concluir e novo';
          if (props.tituloFinanceiro.codigoConta !== undefined && props.tituloFinanceiro.codigoConta > 0) {
            tituloFinanceiroBase.titulo.codigoConta = props.tituloFinanceiro.codigoConta;
            tituloFinanceiroBase.titulo.codigoEmpresa = props.tituloFinanceiro.codigoEmpresa;
            tituloFinanceiroBase.titulo.numeroTitulo = props.tituloFinanceiro.numeroTitulo;
            tituloFinanceiroBase.titulo.dataVencimento = props.tituloFinanceiro.dataVencimento;
            tituloFinanceiroBase.titulo.valorTotal = props.tituloFinanceiro.valorTotal;
            tituloFinanceiroBase.titulo.saldo = props.tituloFinanceiro.saldo;
          }
        } else {
          modalBase.textoBotaoSalvar = 'Salvar alterações';
          modalBase.textoBotaoSalvarNovo = 'Salvar e novo';
          const autorizacaoEditar = await servicoSistema.obterPermissoesAutorizacoesUsuario('AUT_ALTERACAO_MOVIMENTO_FINANCEIRO_BAIXADO');
          state.editavelBaixa = servicoSistema.verificaAutorizacao(tituloFinanceiroBase.titulo.codigoEmpresa, 'AUT_ALTERACAO_MOVIMENTO_FINANCEIRO_BAIXADO', autorizacaoEditar);
          await preencherTitulo();
          if (!props.editavel) {
            tituloFinanceiroBase.editavel = props.editavel;
          }
        }
      }
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      tituloFinanceiroBase,
      ESazonalidade,
      EPermissaoDados,
      EStatusTituloFinanceiro,
      storeSistema,
      UtilitarioGeral,
      apresentarRetornoRequisicao,
      atualizarEmpresaSelecionada,
      obterMensagemRepeticao,
      obterDescricaoSazonalidade,
      salvar,
      atualizarUltimoVencimento,
      atualizarStatus,
      atualizarTipoDocumentoFinanceiroSelecionado,
      EMeioPagamento,
      ETipoTituloFinanceiro,
      atualizarSaldo,
      obterCentrosCustoContaContabil,
      checkRepetir,
      tituloPendente,
      atualizarNumeroBoleto,
      atualizarParcela,
      abrirRemessa,
      atualizarSaldoPeloDetalhamento,
    };
  },
});
